import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import translationPageManager from 'utils/translationPageManager';
import ScoreboardPhaseComponent from './components/ScoreboardPhaseComponent';
import EventCoverComponent from './components/EventCoverComponent';
import PoolInfoComponent from './components/PoolInfoComponent';
import StatisticPhaseComponent from './components/StatisticPhaseComponent';
import BattlePreparationComponent from './components/BattlePreparationComponent';
import StagesPhaseComponent from './components/StagesPhaseComponent';
const TranslationPage = () => {
    const phaseTranslation = useSelector((state) => state.secretaryEventConfigReducer.phaseTranslation);
    const { deleteWindow } = translationPageManager();
    const secretaryCurrentPath = useSelector((state) => state.secretaryEventConfigReducer.secretaryCurrentPath);
    const { eventAddress } = useSecretaryPathParserHook(secretaryCurrentPath);
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    useEffect(() => {
        window.addEventListener('beforeunload', () => deleteWindow());
        return () => {
            window.removeEventListener('beforeunload', () => deleteWindow());
        };
    }, []);
    const translationPage = {
        'coverPhase': _jsx(EventCoverComponent, { image: event ? event.eventCoverImage : null }),
        'stagesPhase': _jsx(StagesPhaseComponent, {}),
        'poolPhase': _jsx(PoolInfoComponent, {}),
        'scoreboardPhase': _jsx(ScoreboardPhaseComponent, {}),
        'statisticPhase': _jsx(StatisticPhaseComponent, {}),
        'battlePreparationPhase': _jsx(BattlePreparationComponent, {})
    };
    return (_jsx("div", Object.assign({ style: styles.container }, { children: translationPage[phaseTranslation ? phaseTranslation : 'coverPhase'] })));
};
export default TranslationPage;
const styles = {
    container: {
        display: 'flex',
        flex: 1,
        width: '100%',
        backgroundColor: '#000'
    },
};
