import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { completeMatch } from 'store/adminEventConfig/adminEventConfig';
import { addFiveSeconds, addTimerLog, giveExtraTime, setIntervalID, updateTimerCounter } from 'store/controlBoardSlice/controlBoardSlice';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import TimerClockFace from 'components/TimerClockFace';
const StartStopMenuComponent = () => {
    const { eventAddress, tournamentAddress, poolAddress } = useSecretaryPathParserHook();
    const event = useSelector((state) => { var _a; return (_a = state.adminEventConfigReducer[eventAddress]) !== null && _a !== void 0 ? _a : null; });
    const stageAddress = event ? event.tournaments[tournamentAddress].currentStageIndex : null;
    const timerCounter = useSelector((state) => state.controlBoardReducer.timerCounter);
    const intervalID = useSelector((state) => state.controlBoardReducer.intervalID);
    const meetingStage = useSelector((state) => state.controlBoardReducer.matchStage);
    const match = useSelector((state) => state.controlBoardReducer);
    const durationSeconds = useSelector((state) => state.controlBoardReducer.matchStage == 'mainStage'
        ? state.controlBoardReducer.mainStageDurationSeconds
        : state.controlBoardReducer.extraStageDurationSeconds);
    const stage = stageAddress || stageAddress === 0
        ? useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress])
        : null;
    const countdown = useSelector((state) => state.controlBoardReducer.countdown);
    const [finishIsHover, setFinishIsHover] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleKeyPress = useCallback((event) => {
        if (event.key === ' ') {
            startStopTimerButtonCallback();
        }
    }, [timerCounter, intervalID]);
    useEffect(() => {
        if (timerCounter && intervalID) {
            clearInterval(intervalID);
            dispatch(setIntervalID(setInterval(() => {
                dispatch(updateTimerCounter());
            }, 50)));
        }
        return () => {
            if (intervalID) {
                clearInterval(intervalID);
            }
            dispatch(setIntervalID(null));
        };
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [timerCounter, intervalID]);
    const startStopTimerButtonCallback = () => {
        if (!timerCounter || (timerCounter && !intervalID)) {
            dispatch(setIntervalID(setInterval(() => {
                dispatch(updateTimerCounter());
            }, 50)));
            dispatch(addTimerLog({ logName: 'старт', time: new Date().getTime() }));
        }
        else {
            if (intervalID) {
                clearInterval(intervalID);
            }
            dispatch(addTimerLog({ logName: 'стоп', time: new Date().getTime() }));
            dispatch(setIntervalID(null));
        }
    };
    const addFiveSecondsButtonCallback = () => {
        dispatch(addFiveSeconds());
    };
    const addExtraTimeCallback = () => {
        dispatch(giveExtraTime(Math.floor(Math.random() * 2)));
    };
    const completeMatchCallback = () => {
        dispatch(addTimerLog({ logName: 'стоп', time: new Date().getTime() }));
        if (intervalID) {
            clearInterval(intervalID);
        }
        dispatch(setIntervalID(null));
        if (stage && (stageAddress || stageAddress === 0)) {
            dispatch(completeMatch({ eventAddress, tournamentAddress, stageAddress, poolAddress, match }));
            if (stage.type === 'pools') {
                navigate('/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage' + '/pool-' + (poolAddress + 1));
            }
            else {
                navigate('/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage');
            }
        }
    };
    return (_jsxs(Container, Object.assign({ style: styles.startStopMenuContainer, maxWidth: false }, { children: [countdown && timerCounter <= 0 && (meetingStage == 'mainStage' || meetingStage == 'extraStage')
                || !countdown && timerCounter / 1000 >= durationSeconds && (meetingStage == 'mainStage' || meetingStage == 'extraStage')
                ? _jsx(Button, Object.assign({ style: styles.extraTimeButton, onClick: () => addExtraTimeCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: meetingStage == 'mainStage'
                            ? 'Дополнительное время'
                            : 'Дополнительное время с приоритетом' })) }))
                : null, _jsx("div", { style: { flex: 6 } }), _jsx(TimerClockFace, { timerCounter: timerCounter > 0 ? timerCounter : 0, numberColor: '#fff', duration: durationSeconds, countdown: countdown }), _jsx("div", { style: { flex: 4 } }), _jsxs(Button, Object.assign({ onClick: () => startStopTimerButtonCallback(), style: styles.startStopBtn }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: styles.helperText }, { children: "Space" })), _jsx(Typography, Object.assign({ style: styles.startStopText }, { children: "\u0421\u0442\u0430\u0440\u0442/\u0421\u0442\u043E\u043F" }))] })), _jsx("div", { style: { flex: 2 } }), _jsx(Button, Object.assign({ onClick: () => addFiveSecondsButtonCallback(), style: styles.addFiveSecondsBtn }, { children: _jsx(Typography, Object.assign({ style: styles.addFiveSecondsText }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C 5 \u0441\u0435\u043A\u0443\u043D\u0434" })) })), _jsx("div", { style: { flex: 4.8 } }), eventAddress && (match.participants[0].count !== match.participants[1].count
                || (stage && stage.type === 'pools' && stage.resolveTies))
                ? _jsx(Typography, Object.assign({ variant: 'h4', onClick: () => completeMatchCallback(), style: {
                        color: '#fff',
                        cursor: 'pointer', textDecoration: finishIsHover ? 'underline' : 'none'
                    }, onMouseEnter: () => setFinishIsHover(true), onMouseLeave: () => setFinishIsHover(false) }, { children: "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u0431\u043E\u0439" }))
                : null] })));
};
export default StartStopMenuComponent;
const styles = {
    startStopMenuContainer: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '3.25rem',
        alignItems: 'center',
        backgroundColor: '#000',
    },
    startStopText: {
        fontSize: '2rem',
        fontWeight: '500'
    },
    startStopBtn: {
        padding: '0.125rem',
        margin: 0,
        height: '4.8125rem',
        width: '26.75rem'
    },
    text: {
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: '400',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        cursor: 'default'
    },
    addFiveSecondsBtn: {
        padding: '0.5rem',
        border: '0.0625rem solid #D6FF35',
        borderRadius: '0.625rem',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: '#000'
    },
    addFiveSecondsText: {
        fontSize: '1.125rem',
        fontWeight: '500',
        color: '#fff'
    },
    extraTimeButton: {
        height: '2.5rem',
        margin: '1.5625rem 0'
    },
    helperText: {
        position: 'absolute',
        top: '-1.5rem',
        alignSelf: 'center',
        color: 'rgba(213, 221, 223, 0.50)'
    }
};
