const recursiveHeightCounter = (roundIndex, sizeUnit) => {
    if (roundIndex > 0) {
        return 2 * recursiveHeightCounter(roundIndex - 1, sizeUnit) + 2 * sizeUnit;
    }
    else {
        return 0;
    }
};
export const topMarginCounter = (roundIndex, meetingIndex, sizeUnit, playoffRoundsLength) => {
    return (sizeUnit
        + sizeUnit * 4 * meetingIndex
        + recursiveHeightCounter(roundIndex !== playoffRoundsLength - 1 ? roundIndex : roundIndex - 1, sizeUnit)
        + recursiveHeightCounter(roundIndex !== playoffRoundsLength - 1 ? roundIndex : roundIndex - 1, sizeUnit) * 2 * meetingIndex);
};
