import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { switchCurrentStageIndex } from 'store/adminEventConfig/adminEventConfig';
import { winRateCounter } from 'utils/winRateCounter';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import WithTournamentsNavigationBar from 'pages/event/components/WithTournamentSubmenu';
import WithStagesNavigationBar from 'pages/event/components/WithStagesNavigationBar';
import ResultsTableHeader from './components/ResultsTableHeader';
import ResultsParticipantRaw from './components/ResultsParticipantRaw';
const StageResultsPage = () => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const currentStageIndex = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const dispatch = useDispatch();
    const stageParticipants = stage.participants.length > 0
        ? [...stage.participants].sort((firstParticipant, secondParticipant) => {
            return winRateCounter(secondParticipant) - winRateCounter(firstParticipant);
        })
        : [];
    const [participants, setParticipants] = useState([...stageParticipants].map((participant, participantIndex) => {
        return {
            data: participant,
            place: participantIndex
        };
    }));
    useEffect(() => {
        setParticipants([...stageParticipants].map((participant, participantIndex) => {
            return {
                data: participant,
                place: participantIndex
            };
        }));
    }, [stage]);
    const switchStageCallback = (isUpStageIndex) => {
        dispatch(switchCurrentStageIndex({ eventAddress, tournamentAddress, stageAddress, isUpStageIndex }));
    };
    const sorting = (byField) => {
        if (byField === 'byPlace') {
            setParticipants(stageParticipants.map((participant, participantIndex) => {
                return {
                    data: participant,
                    place: participantIndex
                };
            }));
            return;
        }
        if (byField === 'byGoesFurther') {
            const updatedParticipants = [...participants].sort((firstParticipant, secondParticipant) => {
                return (firstParticipant.data.goesFurther === secondParticipant.data.goesFurther)
                    ? 0
                    : firstParticipant.data.goesFurther
                        ? -1
                        : 1;
            });
            setParticipants(updatedParticipants);
            return;
        }
        const sortedParticipants = [...participants].sort((firstParticipant, secondParticipant) => {
            switch (byField) {
                case 'byWR':
                    return winRateCounter(secondParticipant.data) - winRateCounter(firstParticipant.data);
                case 'byWins':
                    return secondParticipant.data.win - firstParticipant.data.win;
                case 'byLoses':
                    return secondParticipant.data.lose - firstParticipant.data.lose;
                case 'byDraws':
                    return secondParticipant.data.draw - firstParticipant.data.draw;
                case 'byDone':
                    return secondParticipant.data.count - firstParticipant.data.count;
                case 'byConceded':
                    return secondParticipant.data.conceded - firstParticipant.data.conceded;
            }
        });
        setParticipants(sortedParticipants);
    };
    return (stage.type === 'pools'
        ? _jsxs("div", Object.assign({ style: styles.container }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: { color: 'rgba(0, 0, 0, 0.50)', marginBottom: '1rem' } }, { children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u044D\u0442\u0430\u043F\u0430" })), _jsx(ResultsTableHeader, { setParticipants: sorting }), participants.map((participant) => {
                    return (_jsx(ResultsParticipantRaw, { name: participant.data.name, place: participant.place + 1, WR: winRateCounter(participant.data), wins: participant.data.win, loses: participant.data.lose, draws: participant.data.draw, done: participant.data.count, conceded: participant.data.conceded, goesFurther: participant.data.goesFurther, participantId: participant.data.id, disableCheckboxes: currentStageIndex !== stageAddress }, participant.data.id));
                }), _jsxs("div", Object.assign({ style: styles.buttonsContainer }, { children: [currentStageIndex === stageAddress + 1
                            ? _jsx(Button, Object.assign({ style: styles.button, onClick: () => switchStageCallback(false) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0412\u043D\u0435\u0441\u0442\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F" })) }))
                            : null, currentStageIndex === stageAddress || currentStageIndex === stageAddress + 1
                            ? _jsx(Button, Object.assign({ style: styles.button, onClick: () => switchStageCallback(true), disabled: currentStageIndex === stageAddress + 1 }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0417\u0430\u043A\u043E\u043D\u0447\u0438\u0442\u044C \u044D\u0442\u0430\u043F" })) }))
                            : null] }))] }))
        : null);
};
export default WithTournamentsNavigationBar(WithStagesNavigationBar(StageResultsPage));
const styles = {
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '1rem 0 3rem 0',
    },
    buttonsContainer: {
        width: '100%',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end'
    },
    button: {
        width: 'fit-content',
        marginLeft: '1rem'
    }
};
