export const controlBoardInitialState = {
    matchId: '0',
    stageType: 'pools',
    timerCounter: 0,
    intervalID: null,
    participants: [{
            id: '0',
            name: 'Красный',
            nameClub: '',
            count: 0,
            conceded: 0,
            cards: [],
            isPriorityParticipant: false,
            win: 0,
            lose: 0,
            draw: 0,
            trainerName: '',
            protestCounter: 0,
            rating: '',
            status: false,
            reachedStage: 0,
            goesFurther: false
        }, {
            id: '1',
            name: 'Синий',
            nameClub: '',
            count: 0,
            conceded: 0,
            cards: [],
            isPriorityParticipant: false,
            win: 0,
            lose: 0,
            draw: 0,
            trainerName: '',
            protestCounter: 0,
            rating: '',
            status: false,
            reachedStage: 0,
            goesFurther: false
        }],
    timerLogs: [],
    countdown: false,
    mainStageDurationSeconds: 120,
    extraStageDurationSeconds: 30,
    colors: ['#E46E8B', '#6E9EE4'],
    matchStage: 'mainStage',
    nextParticipants: null
};
export const emptyEvent = {
    eventCoverImage: null,
    eventName: '',
    startDate: '',
    endDate: '',
    city: '',
    eventAddress: '',
    streamUrl: '',
    description: '',
    tournaments: {}
};
export const emptyTournament = {
    tournamentName: '',
    weapon: '',
    tournamentAddress: '',
    matchDuration: '120',
    theEndIsNighAlert: '10',
    participantLimit: '',
    warningCounter: true,
    teamNomination: false,
    doublesCounter: false,
    protestCounter: true,
    redFighterLocation: 'right',
    timerSettings: 'how much left',
    addedValue: '0.5',
    ratingCalculationMethod: 'match points',
    stages: [],
    currentStageIndex: 0,
    participants: []
};
export const POOL_STAGE_DATA = {
    type: 'pools',
    isFinal: false,
    goToTheNext: '2',
    pools: [],
    currentPoolIndex: 0,
    activePools: [],
    notDistributedParticipants: [],
    completedMatches: [],
    participants: [],
    passedParticipants: [],
    resolveTies: false
};
export const PLAYOFF_STAGE_DATA = {
    type: 'playoff',
    isFinal: false,
    goToTheNext: '2',
    playoffSize: '4',
    playoffRounds: [],
    notDistributedParticipants: [],
    participants: [],
    passedParticipants: [],
    isCorrectPlayoff: false
};
export const SWISS_SYSTEM_STAGE_DATA = {
    type: 'swiss system',
    isFinal: 'false',
    goToTheNext: '2',
    withAnOddNumberFightersGive: 'win',
    notDistributedParticipants: [],
    participants: [],
    passedParticipants: []
};
export const STAGE_CHOOSER = {
    'pools': POOL_STAGE_DATA,
    'playoff': PLAYOFF_STAGE_DATA,
    'swiss system': SWISS_SYSTEM_STAGE_DATA
};
