import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import BattlePreparationParticipantComponent from './BattlePreparationParticipantComponent';
const BattlePreparationComponent = () => {
    const colors = useSelector((state) => state.controlBoardReducer.colors);
    const participants = useSelector((state) => state.controlBoardReducer.participants);
    return (_jsxs(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: [_jsx(Typography, Object.assign({ style: styles.nextFightText }, { children: "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0431\u043E\u0439" })), _jsx(BattlePreparationParticipantComponent, { participant: participants[0], color: colors[0] }), _jsx(BattlePreparationParticipantComponent, { participant: participants[1], color: colors[1] })] })));
};
export default BattlePreparationComponent;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
    },
    nextFightText: {
        fontSize: '3.25rem',
        position: 'absolute',
        top: '10rem',
        left: '50%',
        msTransform: 'translate(-50%, 0)',
        transform: 'translate(-50%, 0)',
    },
};
