import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { changeParticipant } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const ResultsParticipantRaw = (props) => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const dispatch = useDispatch();
    const goesFurtherSwitcher = (goesFurther, participantId) => {
        dispatch(changeParticipant({
            eventAddress,
            tournamentAddress,
            stageAddress,
            participantId,
            goesFurther
        }));
    };
    return (_jsxs("div", Object.assign({ style: styles.participantsRowContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.nameTableHeaderField), { color: '#000' }) }, { children: props.name })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem') }, { children: props.place })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem') }, { children: props.WR })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem', '1rem') }, { children: props.wins })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('7.375rem', '1rem') }, { children: props.loses })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem', '1rem') }, { children: props.draws })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('6.625rem', '1rem') }, { children: props.done })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('7.375rem') }, { children: props.conceded })), _jsx("div", { style: { flex: 6 } }), _jsx(Checkbox, { disabled: props.disableCheckboxes, style: { width: '6.5rem' }, checked: props.goesFurther, onChange: (event) => goesFurtherSwitcher(event.target.checked, props.participantId) })] })));
};
export default ResultsParticipantRaw;
const styles = {
    participantsTableHeaderContainer: {
        height: '1.5625rem',
        marginTop: '1.625rem',
        borderRadius: '0.625rem 0.625rem 0 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        backgroundColor: '#111',
    },
    participantsRowContainer: {
        height: '3.3125rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        borderBottom: '0.0625rem solid #6F6F6F',
    },
    nameTableHeaderField: {
        color: '#D6FF35',
        textTransform: 'uppercase',
        fontWeight: '400',
        flex: 15
    },
};
const textStyle = (width, marginRight) => ({
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: '400',
    textAlign: 'center',
    width: width,
    marginRight: marginRight ? marginRight : 0
});
