import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
const VerifiableTextField = (props) => {
    return (_jsx(TextField, { fullWidth: true, id: props.fieldName, name: props.fieldName, disabled: !props.editable
            || (props.fieldName == 'nominationAddress' && props.fromPage == 'nominationDescription')
            || (props.fieldName == 'tournamentAddress' && props.fromPage == 'tournamentDescription'), autoComplete: 'off', InputProps: {
            inputProps: { min: 0 }
        }, type: props.fieldType, value: props.formik.values[props.fieldName], onChange: props.formik.handleChange, error: props.formik.touched[props.fieldName] && Boolean(props.formik.errors[props.fieldName]), helperText: props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] }));
};
export default VerifiableTextField;
