import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createStateSyncMiddleware } from 'redux-state-sync';
import controlBoardSlice from 'store/controlBoardSlice/controlBoardSlice';
import adminEventConfig from 'store/adminEventConfig/adminEventConfig';
import secretaryEventConfig from 'store/secretaryEventConfig/secretaryEventConfig';
import navigationBarConfig from 'store/navigationBarConfig/navigationBarConfig';
const rootReducer = combineReducers({
    controlBoardReducer: controlBoardSlice,
    adminEventConfigReducer: adminEventConfig,
    secretaryEventConfigReducer: secretaryEventConfig,
    navigationBarConfigReducer: navigationBarConfig,
});
const config = {
    key: 'root',
    storage: storage,
};
const persistedReducer = persistReducer(config, rootReducer);
const syncStateMiddleware = [createStateSyncMiddleware({ blacklist: [PERSIST] })];
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            warnAfter: 128
        },
        immutableCheck: { warnAfter: 128 },
    }).concat(syncStateMiddleware),
    devTools: true
});
export default () => {
    let persistor = persistStore(store);
    return { store, persistor };
};
const persistor = persistStore(store);
export { store, persistor };
