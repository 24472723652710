import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
const TournamentCheckbox = (props) => {
    return (_jsxs(Container, Object.assign({ style: styles.container }, { children: [_jsx(Checkbox, { id: props.fieldName, name: props.fieldName, checked: props.formik.values[props.fieldName], onChange: props.formik.handleChange, disabled: !props.editable, style: { marginRight: '0.5rem' } }), _jsx(Typography, Object.assign({ variant: 'h4', style: styles.title }, { children: props.title }))] })));
};
export default TournamentCheckbox;
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '2rem',
        width: '16.125rem',
        alignItems: 'center',
        padding: 0
    },
    title: {
        whiteSpace: 'nowrap',
        lineHeight: '1.5rem',
    },
};
