import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import ParticipantItemComponent from './ParticipantItemComponent';
const ParticipantInPoolComponent = (props) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'participant',
        item: () => {
            const movingParticipantIndex = props.participantIndex;
            const fromPool = props.poolIndex;
            const stage = props.stage;
            const setPoolStage = props.setPoolStage;
            return { movingParticipantIndex, fromPool, stage, setPoolStage };
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging()
            };
        }
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return (_jsx("div", Object.assign({ ref: ref, style: Object.assign(Object.assign({}, styles.participantContainer), { opacity }) }, { children: _jsx(ParticipantItemComponent, { participant: props.participant, groups: props.groups }) })));
};
export default ParticipantInPoolComponent;
const styles = {
    participantContainer: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    }
};
