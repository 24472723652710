import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { deleteStage } from 'store/adminEventConfig/adminEventConfig';
const StagesMenuButtonsRow = (props) => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMutedButton = (buttonName) => {
        if (buttonName === 'settings') {
            const gluedPathname = '/event/' + eventAddress + '/tournament/' + tournamentAddress + '/stage/' + (stageAddress ? stageAddress : props.stageIndex);
            return pathname === gluedPathname;
        }
        else if (buttonName === 'bracket') {
            return pathname.includes('bracket');
        }
        else {
            return pathname.includes('results');
        }
    };
    const paths = {
        'settings': `/event/${eventAddress}/tournament/${tournamentAddress}/stage/${stageAddress ? stageAddress : props.stageIndex}`,
        'bracket': `/event/${eventAddress}/tournament/${tournamentAddress}/stage/${stageAddress ? stageAddress : props.stageIndex}/bracket`,
        'results': `/event/${eventAddress}/tournament/${tournamentAddress}/stage/${stageAddress ? stageAddress : props.stageIndex}/results`
    };
    const deleteStageCallback = (stageIndex) => {
        dispatch(deleteStage({
            eventAddress,
            tournamentAddress,
            stageAddress: stageIndex
        }));
        navigate(`/event/${eventAddress}/tournament/${tournamentAddress}/stages`);
    };
    return (_jsxs("div", Object.assign({ style: styles().buttonsContainer }, { children: [_jsx(Button, Object.assign({ style: styles(isMutedButton('settings')).buttons, href: paths['settings'] }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438" })) })), _jsx(Button, Object.assign({ style: styles(isMutedButton('bracket')).buttons, href: paths['bracket'] }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u0421\u0435\u0442\u043A\u0430" })) })), _jsx(Button, Object.assign({ style: styles(isMutedButton('results')).buttons, href: paths['results'] }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433" })) })), _jsx(Typography, Object.assign({ variant: 'h4', style: styles().deleteStageButton, onClick: () => deleteStageCallback(props.stageIndex) }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u044D\u0442\u0430\u043F" }))] })));
};
export default StagesMenuButtonsRow;
const styles = (isTransparentBackground) => ({
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '2.5rem',
        padding: 0,
        alignItems: 'center'
    },
    buttons: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        border: '0.125rem solid #D6FF35',
        backgroundColor: isTransparentBackground ? 'transparent' : '#D6FF35',
    },
    deleteStageButton: {
        cursor: 'default',
        marginLeft: '0.5rem'
    },
    buttonText: {
        lineHeight: 'normal',
        textTransform: 'uppercase'
    }
});
