import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { deleteFromBracket } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { matchesGenerator } from 'utils/matchesGenerator';
import TournamentsMenuButtonsRow from 'pages/event/components/TournamentsMenuButtonsRow';
const WithTournamentSubmenu = (WrappedComponent) => (props) => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages.length > 0
        ? state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]
        : null);
    const poolStage = stage && stage.type === 'pools' ? stage : null;
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [{ isOverCurrent }, drop] = useDrop({
        accept: ['participant'],
        collect(monitor) {
            return {
                isOverCurrent: monitor.isOver({ shallow: true }),
            };
        },
        drop(item) {
            if (!ref.current || !isOverCurrent) {
                return;
            }
            if (item.fromRound || item.fromRound === 0) {
                dispatch(deleteFromBracket({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    fromRound: item.fromRound,
                    fromMatch: item.fromMatch,
                    fromPlace: item.fromPlace
                }));
            }
            if (!poolStage) {
                return;
            }
            if (item.stage && item.stage.type === 'pools') {
                if (item.fromPool || item.fromPool === 0) {
                    const exportedPoolParticipants = [...item.stage.pools[item.fromPool].participants];
                    const exportedParticipant = exportedPoolParticipants.splice(item.movingParticipantIndex, 1)[0];
                    let updatedNotDistributedParticipants = [...item.stage.notDistributedParticipants];
                    updatedNotDistributedParticipants.push(exportedParticipant);
                    if (updatedNotDistributedParticipants.length > 1) {
                        updatedNotDistributedParticipants.sort((firstParticipants, secondParticipants) => {
                            return Number(secondParticipants.rating) - Number(firstParticipants.rating);
                        });
                    }
                    const updatedPools = [...item.stage.pools];
                    updatedPools[item.fromPool] = {
                        matches: matchesGenerator({
                            participants: exportedPoolParticipants,
                            matches: []
                        }, item.fromPool, tournament),
                        participants: exportedPoolParticipants,
                    };
                    item.setPoolStage(Object.assign(Object.assign({}, item.stage), { pools: updatedPools, notDistributedParticipants: updatedNotDistributedParticipants }));
                }
            }
        }
    });
    drop(ref);
    return (tournament
        ? _jsxs(Container, Object.assign({ ref: ref, style: styles.mainContainer, maxWidth: false }, { children: [_jsxs(Container, Object.assign({ style: styles.tournamentMenuContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', style: styles.tournamentNameText }, { children: tournament.tournamentName })), _jsx(TournamentsMenuButtonsRow, { tournament: tournament })] })), _jsx(WrappedComponent, Object.assign({}, props))] }))
        : null);
};
export default WithTournamentSubmenu;
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingTop: '1.4375rem',
        paddingLeft: '8.375rem',
        paddingRight: '8.375rem',
    },
    tournamentMenuContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: '2.5rem',
        padding: 0,
        marginTop: '0.1875rem',
        marginBottom: '1.8125rem',
    },
    tournamentNameText: {
        marginRight: '1rem'
    }
};
