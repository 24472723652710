import { jsx as _jsx } from "react/jsx-runtime";
export const FullWidthLayout = (props) => {
    const { children } = props;
    return (_jsx("div", Object.assign({ style: styles.container }, { children: children })));
};
const styles = {
    container: {
        flex: 1,
        width: '100vw',
    }
};
