import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { changeStage } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const StageBracketTopRowComponent = (props) => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const dispatch = useDispatch();
    const addPoolCallback = () => {
        if (props.poolStage) {
            props.setPoolStage(Object.assign(Object.assign({}, props.poolStage), { pools: [...props.poolStage.pools, { matches: [], participants: [] }], activePools: [...props.poolStage.activePools, props.poolStage.activePools.length] }));
        }
    };
    const savePoolsCallback = () => {
        dispatch(changeStage({
            eventAddress,
            tournamentAddress,
            stageAddress,
            stage: Object.assign({}, props.poolStage)
        }));
    };
    return (_jsx("div", Object.assign({ style: styles.topRowContainer }, { children: _jsxs("div", Object.assign({ style: styles.participantsInfoContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', style: styles.participantsInfoText }, { children: `Участники: ${props.participants.length}` })), _jsx(Typography, Object.assign({ variant: 'h2', style: styles.participantsInfoText }, { children: `Выходят дальше: ${props.poolStage.goToTheNext}` })), _jsx("div", { style: { flex: 1 } }), _jsx(Button, Object.assign({ onClick: () => addPoolCallback(), style: styles.addPoolButton }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: 'Добавить пул' })) })), _jsx(Button, Object.assign({ style: styles.saveButton, disabled: props.poolStage === props.savedStage, onClick: () => savePoolsCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: 'Сохранить' })) }))] })) })));
};
export default StageBracketTopRowComponent;
const styles = {
    topRowContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        marginBottom: '2rem',
    },
    participantsInfoText: {
        marginRight: '2rem'
    },
    participantsInfoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        marginRight: '2rem',
    },
    addPoolButton: {
        width: '13.9375rem',
    },
    saveButton: {
        marginLeft: '1rem'
    },
};
