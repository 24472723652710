import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from '@mui/material';
const EventCoverComponent = (props) => {
    return (_jsx(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: props.image
            ? _jsx("img", { style: styles.image, src: props.image, alt: 'cover' })
            : null })));
};
export default EventCoverComponent;
const styles = {
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        height: '100%',
        width: '100%',
        bottom: 0,
        left: 0,
        margin: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        position: 'absolute',
        right: 0,
        top: 0
    }
};
