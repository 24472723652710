import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { dropFromBracketToBracket, dropFromListToBracket } from 'store/adminEventConfig/adminEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const EmptyParticipant = (props) => {
    const { eventAddress, tournamentAddress } = props.isSecretaryPage ? useSecretaryPathParserHook() : useAdminPathParserHook();
    const stageAddress = props.isSecretaryPage
        ? useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex)
        : useAdminPathParserHook().stageAddress;
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [{ isOverCurrent }, drop] = useDrop({
        accept: ['participant'],
        collect(monitor) {
            return {
                isOverCurrent: monitor.isOver({ shallow: true }),
            };
        },
        drop(item) {
            if (!ref.current) {
                return;
            }
            if (item.participant) {
                dispatch(dropFromListToBracket({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    toRound: props.roundIndex,
                    toMatch: props.matchIndex,
                    toPlace: props.isFirstParticipant ? 0 : 1,
                    participant: item.participant
                }));
            }
            else {
                dispatch(dropFromBracketToBracket({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    fromRound: item.fromRound,
                    fromMatch: item.fromMatch,
                    fromPlace: item.fromPlace,
                    toRound: props.roundIndex,
                    toMatch: props.matchIndex,
                    toPlace: props.isFirstParticipant ? 0 : 1,
                }));
            }
        }
    });
    !props.isMatchForThirdPlace ? drop(ref) : null;
    return (_jsx("div", { ref: ref, style: { flex: 1 } }));
};
export default EmptyParticipant;
