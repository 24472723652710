import { jsx as _jsx } from "react/jsx-runtime";
import PoolsListComponent from 'components/PoolsListComponent';
const TranslationPoolsStageComponent = (props) => {
    return (_jsx("div", Object.assign({ style: styles.container }, { children: _jsx(PoolsListComponent, { stage: props.stage, isSecretaryPage: false }) })));
};
export default TranslationPoolsStageComponent;
const styles = {
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }
};
