import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button, Container } from '@mui/material';
import { addCard, addProtest, giveTechnicalLose, increaseParticipantCount } from 'store/controlBoardSlice/controlBoardSlice';
import { useStretchingText } from 'utils/useStretchingText';
import ControlBoardButton from './ControlBoardButton';
import PenaltyCardComponent from 'pages/standalone/components/PenaltyCardComponent';
const cardsColors = {
    'red': '#F00',
    'yellow': '#FFE600',
    'black': '#000'
};
const ParticipantPanelComponent = (props) => {
    const participantColor = useSelector((state) => state.controlBoardReducer.colors);
    const participant = useSelector((state) => state.controlBoardReducer.participants[props.participantNumber]);
    const intervalID = useSelector((state) => state.controlBoardReducer.intervalID);
    const dispatch = useDispatch();
    useStretchingText('participantNameText', participant.cards.length);
    const giveCardBtn = (card) => {
        dispatch(addCard({ participantNumber: props.participantNumber, card }));
    };
    const givePointBtn = (points) => {
        dispatch(increaseParticipantCount({
            participantNumber: props.participantNumber,
            addedCount: points
        }));
        navigator.serviceWorker.ready.then((registration) => registration.sync.register('post-data'));
    };
    const addProtestCallback = () => {
        dispatch(addProtest({ participantNumber: props.participantNumber }));
    };
    const technicalLoseCallback = () => {
        dispatch(giveTechnicalLose({ participantNumber: props.participantNumber }));
        if (intervalID) {
            clearInterval(intervalID);
        }
    };
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.participantPanelContainer), { backgroundColor: participantColor[props.participantNumber] }) }, { children: [_jsxs(Container, Object.assign({ style: styles.topButtonsContainer, maxWidth: false }, { children: [_jsx(ControlBoardButton, { pressBtn: () => giveCardBtn('yellow'), text: 'Желтая карточка', disabled: participant.cards.length > 0 }), _jsx(ControlBoardButton, { pressBtn: () => giveCardBtn('red'), text: 'Красная карточка' }), _jsx(ControlBoardButton, { pressBtn: () => giveCardBtn('black'), text: 'Черная карточка' })] })), _jsx("div", { style: styles.separator }), _jsx(Typography, Object.assign({ style: styles.count }, { children: participant.count })), _jsx("div", { style: styles.smallSeparator }), _jsxs("div", Object.assign({ style: styles.participantInfoContainer }, { children: [participant.isPriorityParticipant
                        ? _jsx("img", { src: '/participantCrown.svg', alt: 'participantCrown', style: { marginRight: '1rem' } })
                        : null, _jsx("div", Object.assign({ style: styles.participantNameContainer }, { children: _jsx(Typography, Object.assign({ className: "participantNameText", noWrap: true, style: styles.participantName }, { children: participant.name })) })), _jsx("div", Object.assign({ style: styles.penaltyCardsContainer }, { children: participant.cards.map((card, cardIndex) => {
                            return _jsx(PenaltyCardComponent, { participantNumber: props.participantNumber, cardIndex: cardIndex, card: cardsColors[card], deletable: true }, cardIndex);
                        }) }))] })), participant.protestCounter
                ? _jsx(Typography, Object.assign({ variant: 'h4', style: { alignSelf: 'center', marginTop: '1rem' } }, { children: 'Протестов: ' + participant.protestCounter }))
                : null, _jsx("div", { style: styles.separator }), _jsxs(Container, Object.assign({ style: styles.bottomButtonsContainer, maxWidth: false }, { children: [_jsx(ControlBoardButton, { pressBtn: () => givePointBtn(1), helperText: props.participantNumber === 0 ? '←' : '→', text: '+1' }), _jsx(ControlBoardButton, { pressBtn: () => givePointBtn(2), text: '+2' }), _jsx(ControlBoardButton, { pressBtn: () => givePointBtn(3), text: '+3' }), _jsx(ControlBoardButton, { pressBtn: () => givePointBtn(-1), disabled: participant.count <= 0, helperText: props.participantNumber === 0 ? 'Shift + ←' : 'Shift + →', text: '-1' }), _jsx("div", { style: styles.bottomButtonsMainSeparator }), props.showProtestCounter
                        ? _jsx(Button, Object.assign({ onClick: () => addProtestCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u041F\u0440\u043E\u0442\u0435\u0441\u0442" })) }))
                        : null, _jsx("div", { style: styles.bottomButtonsMainSeparator }), _jsx(Button, Object.assign({ onClick: () => technicalLoseCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0422\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u043F\u043E\u0440\u0430\u0436\u0435\u043D\u0438\u0435" })) }))] }))] })));
};
export default ParticipantPanelComponent;
const styles = {
    participantPanelContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        maxWidth: '50%',
        paddingLeft: '2.875rem',
        paddingRight: '2.875rem',
        paddingTop: '1.9375rem',
        paddingBottom: '1.9375rem',
    },
    topButtonsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 0
    },
    participantName: {
        textAlign: 'center',
        fontSize: '2.5rem',
        fontWeight: '400',
        lineHeight: '2.5rem',
        position: 'absolute',
        top: 0,
        height: 'auto',
        width: 'auto',
        whiteSpace: 'nowrap',
    },
    count: {
        textAlign: 'center',
        fontSize: '8rem',
        fontWeight: '400',
        display: 'block',
        lineHeight: '4.5rem'
    },
    smallSeparator: {
        flex: 2
    },
    separator: {
        flex: 3
    },
    bottomButtonsContainer: {
        height: '2.5rem',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
    leftBottomButtonsSeparator: {
        width: '0.3125rem'
    },
    bottomButtonsMainSeparator: {
        flex: 1
    },
    participantInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center'
    },
    participantNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: 40,
        flex: 1,
    },
    penaltyCardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: 'fit-content',
        overflow: 'inherit'
    }
};
