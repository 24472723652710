import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { setMatch } from 'store/controlBoardSlice/controlBoardSlice';
import { cancelMatch } from 'store/adminEventConfig/adminEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
const ParticipantInMatchComponent = (props) => {
    const { eventAddress, tournamentAddress, poolAddress } = useSecretaryPathParserHook();
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const stage = tournament.stages[stageAddress];
    const completedMatches = stage.type === 'pools' ? stage.completedMatches : null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const startMatchCallback = () => {
        if (stage.type === 'pools') {
            dispatch(setMatch(Object.assign(Object.assign({}, props.match), { nextParticipants: props.nextParticipants })));
        }
        navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage' + '/pool-' + (poolAddress + 1) + '/start-settings'}`);
    };
    const startOrChangeButtonShow = () => {
        if (completedMatches) {
            return completedMatches.find((match) => {
                return match.matchId === props.match.matchId;
            });
        }
    };
    const cancelMatchCallback = () => {
        if (stage.type === 'pools') {
            dispatch(cancelMatch({
                eventAddress,
                tournamentAddress,
                stageAddress,
                poolAddress,
                match: props.match
            }));
            dispatch(setMatch(Object.assign(Object.assign({}, props.match), { nextParticipants: props.nextParticipants })));
            navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage' + '/pool-' + (poolAddress + 1) + '/control-panel'}`);
        }
    };
    return (_jsxs(Grid, Object.assign({ item: true, xs: 6, style: styles(props.participantNumber).gridItemContainer }, { children: [props.participantNumber === 0
                ? _jsx(Typography, Object.assign({ variant: 'h2', style: styles(-1).matchIndexText }, { children: Math.floor(props.matchIndex) + 1 }))
                : null, props.participantNumber === 1
                ? _jsx(Typography, Object.assign({ variant: 'h2', style: styles(-1).greyColorForText }, { children: props.match.participants[props.participantNumber].count ? props.match.participants[props.participantNumber].count : 0 }))
                : null, _jsxs("div", Object.assign({ style: styles(props.participantNumber).participantInfoContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h3', style: styles(-1).greyColorForText }, { children: props.match.participants[props.participantNumber].name })), _jsx(Typography, Object.assign({ variant: 'h4', style: styles(-1).greyColorForTextWithOpacity }, { children: props.match.participants[props.participantNumber].nameClub }))] })), props.participantNumber === 0
                ? _jsx(Typography, Object.assign({ variant: 'h2', style: styles(-1).greyColorForText }, { children: props.match.participants[props.participantNumber].count ? props.match.participants[props.participantNumber].count : 0 }))
                : null, props.participantNumber === 1
                ? _jsx("div", Object.assign({ style: styles(-1).matchesControlButtonsContainer }, { children: !startOrChangeButtonShow()
                        ? _jsx(Button, Object.assign({ onClick: () => startMatchCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u041D\u0430\u0447\u0430\u0442\u044C" })) }))
                        : _jsx(Button, Object.assign({ onClick: () => cancelMatchCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })) })) }))
                : null] })));
};
export default ParticipantInMatchComponent;
const styles = (participantIndex) => ({
    gridItemContainer: {
        height: '4.25rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 1.8125rem',
        borderBottom: '0.0625rem solid #C5C5C5',
        borderTop: participantIndex == 0 || participantIndex == 1
            ? '0.0625rem solid #C5C5C5' : 0,
        borderRight: participantIndex === 0 ? '0.0625rem solid #C5C5C5' : 0,
    },
    participantInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: participantIndex === 0 ? 'flex-start' : 'flex-end',
        justifyContent: 'center',
    },
    greyColorForText: {
        color: '#D5DDDF'
    },
    greyColorForTextWithOpacity: {
        color: 'rgba(197, 197, 197, 0.5)'
    },
    matchesControlButtonsContainer: {
        width: '9.3125rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    matchIndexText: {
        color: '#D6FF35',
        width: '3rem',
        paddingRight: '1rem'
    },
});
