import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
const InputNewParticipantInfoFieldContainer = (props) => {
    return (_jsxs("div", Object.assign({ style: {
            width: props.width,
            height: '4.5rem',
            marginRight: '0.5625rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        } }, { children: [_jsx(Typography, Object.assign({ variant: 'h5', style: { textTransform: 'uppercase' } }, { children: props.title })), _jsx(TextField, { fullWidth: false, id: props.fieldName, name: props.fieldName, disabled: false, autoComplete: 'off', InputProps: {
                    inputProps: { min: 0 }
                }, type: 'text', value: props.formik.values[props.fieldName], onChange: props.formik.handleChange, error: props.formik.touched[props.fieldName] && Boolean(props.formik.errors[props.fieldName]), helperText: props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] })] })));
};
export default InputNewParticipantInfoFieldContainer;
