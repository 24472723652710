import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { changePoolToActive } from 'store/adminEventConfig/adminEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
const PoolsListComponent = (props) => {
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook();
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const tournament = event ? event.tournaments[tournamentAddress] : null;
    const stageAddress = tournament ? tournament.currentStageIndex : -2;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const disablePullCallback = (poolIndex) => {
        return props.stage.activePools.find((index) => index == poolIndex)
            || (props.stage.activePools.find((index) => index == poolIndex) == 0);
    };
    const activatePoolCallback = (poolIndex) => {
        navigate(`${'/' + 'secretary/event' + '/' + eventAddress + '/' + tournamentAddress + '/current-stage' + '/pool-' + (poolIndex + 1)}`);
    };
    const changePoolCallback = (poolIndex) => {
        dispatch(changePoolToActive({
            eventAddress,
            tournamentAddress,
            stageAddress,
            poolAddress: poolIndex
        }));
        activatePoolCallback(poolIndex);
    };
    return (_jsx(Grid, Object.assign({ container: true, spacing: 2, style: styles.gridContainer }, { children: props.stage.pools.map((pool, poolIndex) => {
            return _jsx(Grid, Object.assign({ item: true, xs: 4 }, { children: _jsxs("div", Object.assign({ style: styles.poolContainer }, { children: [props.isSecretaryPage
                            ? _jsx(Typography, Object.assign({ variant: 'h2', color: 'text.primary', style: styles.poolIndexText }, { children: `Пул ${poolIndex + 1}` }))
                            : null, _jsx("div", Object.assign({ style: styles.gridItemContainer }, { children: _jsx("div", Object.assign({ style: styles.gridItemInnerContainer }, { children: pool.participants.map((participant, index) => {
                                    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.participantItemContainer), { borderBottom: index < pool.participants.length - 1
                                                ? '0.0625rem solid #FFF'
                                                : 0 }) }, { children: [_jsxs("div", Object.assign({ style: styles.nameAndClubTextsContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', color: '#fff' }, { children: participant.name })), _jsx(Typography, Object.assign({ variant: 'h4', color: '#fff' }, { children: participant.nameClub }))] })), _jsx(Typography, Object.assign({ variant: 'h4', color: '#fff' }, { children: participant.rating }))] }), participant.id));
                                }) })) })), _jsxs("div", Object.assign({ style: styles.buttonsContainer }, { children: [props.isSecretaryPage
                                    ? _jsx(Button, Object.assign({ fullWidth: true, disabled: !disablePullCallback(poolIndex) }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: { width: '100%', color: disablePullCallback(poolIndex) ? '#000' : '#fff' }, onClick: () => activatePoolCallback(poolIndex) }, { children: !disablePullCallback(poolIndex)
                                                ? 'Пул прошел'
                                                : 'Запустить пул' })) }))
                                    : null, props.isSecretaryPage && !disablePullCallback(poolIndex)
                                    ? _jsx(Button, Object.assign({ style: styles.changePoolButton }, { children: _jsx(Typography, Object.assign({ variant: 'h2', onClick: () => changePoolCallback(poolIndex) }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })) }))
                                    : null] }))] })) }), poolIndex);
        }) })));
};
export default PoolsListComponent;
const styles = {
    gridContainer: {
        padding: 0,
    },
    poolContainer: {
        marginLeft: '2rem',
        marginRight: '2rem',
    },
    poolIndexText: {
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '1rem'
    },
    gridItemContainer: {
        border: '0.0625rem solid #FFF',
        borderRadius: '0.625rem',
        marginTop: '1rem'
    },
    gridItemInnerContainer: {
        border: '0.0625rem solid #FFF',
        borderRadius: '0.625rem',
        margin: '1rem',
    },
    participantItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0.5rem 2.25rem 0.5rem 2.25rem',
        alignItems: 'center',
    },
    nameAndClubTextsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    changePoolButton: {
        width: '18.0625rem',
        marginLeft: '1rem'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '1rem'
    },
};
