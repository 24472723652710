import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { changePhase } from 'store/secretaryEventConfig/secretaryEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import translationPageManager from 'utils/translationPageManager';
import Error404 from 'components/Error404';
const SecretaryEventPage = () => {
    const { pathname } = useLocation();
    const { eventAddress } = useSecretaryPathParserHook();
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const tournaments = event ? event.tournaments : null;
    const [tournamentsDivisionByWeapon, setTournamentsDivisionByWeapon] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { checkTranslationPageAreExisting, openTranslationPage } = translationPageManager();
    useEffect(() => {
        if (tournaments) {
            dispatch(changePhase({
                phaseTranslation: 'coverPhase',
                currentSecretaryPath: pathname
            }));
            checkTranslationPageAreExisting();
            const reworkedTournaments = {};
            for (const [key, value] of Object.entries(tournaments)) {
                reworkedTournaments[value.weapon] =
                    reworkedTournaments[value.weapon]
                        ? [...reworkedTournaments[value.weapon],
                            {
                                tournamentName: value.tournamentName,
                                tournamentAddress: key,
                                stageIndex: value.currentStageIndex
                            }]
                        : [{
                                tournamentName: value.tournamentName,
                                tournamentAddress: key,
                                stageIndex: value.currentStageIndex
                            }];
            }
            setTournamentsDivisionByWeapon(reworkedTournaments);
        }
    }, []);
    const tournamentPressCallback = (tournamentAddress, stageIndex) => {
        if (tournaments) {
            const stageType = tournaments[tournamentAddress].stages.length > 0 ? tournaments[tournamentAddress].stages[stageIndex].type : null;
            if (stageType) {
                navigate(`${tournamentAddress + '/current-stage'}`);
            }
        }
    };
    return (tournaments
        ? _jsxs(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: [_jsx(Button, Object.assign({ style: styles.openTranslationPageButton, onClick: () => openTranslationPage() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u043E\u043A\u043D\u043E \u0442\u0440\u0430\u043D\u0441\u043B\u044F\u0446\u0438\u0438" })) })), _jsx("div", { style: { flex: 5 } }), _jsx(Typography, Object.assign({ variant: 'h1', color: 'text.primary' }, { children: 'Номинации' })), _jsx("div", { style: { flex: 4 } }), Object.keys(tournamentsDivisionByWeapon).map(weaponKey => {
                    return (_jsxs("div", Object.assign({ style: styles.weaponDivisionContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', style: Object.assign(Object.assign({}, styles.listItemText), { textTransform: 'uppercase' }) }, { children: weaponKey })), tournamentsDivisionByWeapon[weaponKey].map((tournamentInfo) => {
                                return (_jsx(Typography, Object.assign({ variant: 'h2', style: styles.listItemText, onClick: () => {
                                        tournamentPressCallback(tournamentInfo.tournamentAddress, tournamentInfo.stageIndex);
                                    } }, { children: tournamentInfo.tournamentName }), tournamentInfo.tournamentName));
                            })] }), weaponKey));
                }), _jsx("div", { style: { flex: 15 } })] }))
        : _jsx(Error404, {}));
};
export default SecretaryEventPage;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
    },
    weaponDivisionContainer: {
        alignItems: 'center',
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    },
    listItemText: {
        color: '#fff',
        lineHeight: '4.5rem',
        textAlign: 'center',
        cursor: 'default'
    },
    openTranslationPageButton: {
        alignSelf: 'end',
        marginRight: '2rem',
        marginTop: '2rem'
    },
};
