import { createSlice } from '@reduxjs/toolkit';
import { controlBoardInitialState } from 'store/mockData';
const cardsConverter = {
    'red': 'красная карточка',
    'yellow': 'желтая карточка',
    'black': 'черная карточка'
};
const colorsConverter = {
    '#6E9EE4': 'синий',
    '#E46E8B': 'красный'
};
const controlBoardSlice = createSlice({
    name: 'controlBoard',
    initialState: controlBoardInitialState,
    reducers: {
        updateTimerCounter(state) {
            if ((state.countdown && state.timerCounter > 0)
                || (!state.countdown && state.timerCounter < (state.matchStage == 'mainStage'
                    ? state.mainStageDurationSeconds * 1000
                    : state.extraStageDurationSeconds * 1000))) {
                state.timerCounter = state.countdown
                    ? state.timerCounter - 50
                    : state.timerCounter + 50;
            }
            else {
                if (state.intervalID) {
                    clearInterval(state.intervalID);
                }
                state.intervalID = null;
                state.timerLogs.push({ time: new Date().getTime(), logName: 'конец' });
            }
        },
        setIntervalID(state, action) {
            state.intervalID = action.payload;
        },
        setParticipant(state, action) {
            state.participants[action.payload.participantNumber] = action.payload.participantData;
        },
        addTimerLog(state, action) {
            state.timerLogs.push(action.payload);
        },
        addCard(state, action) {
            let logName = cardsConverter[action.payload.card] + ' ' + colorsConverter[state.colors[action.payload.participantNumber]];
            state.participants[action.payload.participantNumber].cards.push(action.payload.card);
            state.timerLogs.push({
                logName: logName,
                time: new Date().getTime()
            });
        },
        clearLogs(state) {
            state.timerLogs = [];
        },
        setCountdown(state, action) {
            state.countdown = action.payload;
            state.timerCounter = action.payload
                ? state.matchStage == 'mainStage'
                    ? state.mainStageDurationSeconds * 1000
                    : state.extraStageDurationSeconds * 1000
                : 0;
        },
        setDuration(state, action) {
            state.timerCounter = state.countdown ? action.payload * 1000 : 0;
            if (state.matchStage == 'mainStage') {
                state.mainStageDurationSeconds = action.payload;
            }
            else {
                state.extraStageDurationSeconds = action.payload;
            }
        },
        addFiveSeconds(state) {
            if (state.countdown && state.timerCounter < state.mainStageDurationSeconds * 1000 - 5000) {
                state.timerCounter = state.timerCounter + 5000;
                state.timerLogs.push({ logName: '+5 секунд', time: new Date().getTime() });
            }
            else if (!state.countdown && state.timerCounter > 5000) {
                state.timerCounter = state.timerCounter - 5000;
                state.timerLogs.push({ logName: '+5 секунд', time: new Date().getTime() });
            }
        },
        increaseParticipantCount(state, action) {
            state.participants[action.payload.participantNumber].count += action.payload.addedCount;
            state.timerLogs.push({
                logName: `${action.payload.addedCount >= 0 ? '+' : ''}${action.payload.addedCount} ${state.participants[action.payload.participantNumber].name}`,
                time: new Date().getTime()
            });
        },
        restartFight(state) {
            state.timerCounter = state.countdown ? state.mainStageDurationSeconds * 1000 : 0;
            state.timerLogs = [];
            state.intervalID = null;
            state.participants = state.participants.map((part) => {
                return Object.assign(Object.assign({}, part), { count: 0, cards: [], protestCounter: 0 });
            });
            state.matchStage = 'mainStage';
            state.participants.map((participant) => {
                participant.isPriorityParticipant = false;
            });
        },
        swapParticipants(state) {
            state.participants.reverse();
            state.colors.reverse();
        },
        giveExtraTime(state, action) {
            state.countdown = true;
            state.timerCounter = state.extraStageDurationSeconds * 1000;
            if (state.matchStage == 'mainStage') {
                state.matchStage = 'extraStage';
                state.timerLogs.push({ time: new Date().getTime(), logName: 'дополнительное время' });
            }
            else if (state.matchStage == 'extraStage') {
                state.matchStage = 'extraStageWithPriority';
                state.participants[action.payload].isPriorityParticipant = true;
                state.timerLogs.push({
                    time: new Date().getTime(),
                    logName: `дополнительное время с приоритетом ${state.participants[action.payload].name}`
                });
            }
        },
        deleteParticipantCard(state, action) {
            state.participants[action.payload.participantNumber].cards
                = state.participants[action.payload.participantNumber].cards.filter((card, cardIndex) => {
                    return cardIndex !== action.payload.indexOfCardToDeleted;
                });
        },
        setMatch(state, action) {
            return Object.assign(Object.assign({}, action.payload), { intervalID: null });
        },
        addProtest(state, action) {
            state.participants[action.payload.participantNumber].protestCounter += 1;
            state.timerLogs.push({
                time: new Date().getTime(),
                logName: `${state.participants[action.payload.participantNumber].name + ' подал протест'}`
            });
        },
        giveTechnicalLose(state, action) {
            const participantNumber = action.payload.participantNumber === 0 ? 1 : 0;
            state.intervalID = null;
            state.participants[participantNumber].count = 1;
            state.participants[action.payload.participantNumber].count = 0;
            state.timerLogs.push({
                time: new Date().getTime(),
                logName: `${state.participants[action.payload.participantNumber].name + ' - техническое поражение'}`
            });
            state.timerCounter = state.countdown ? state.mainStageDurationSeconds * 1000 : 0;
            state.matchStage = 'mainStage';
            state.participants.map((participant) => {
                participant.isPriorityParticipant = false;
                participant.protestCounter = 0;
            });
        }
    }
});
export default controlBoardSlice.reducer;
export const { updateTimerCounter, setIntervalID, setParticipant, addTimerLog, addCard, clearLogs, setCountdown, addFiveSeconds, setDuration, increaseParticipantCount, restartFight, swapParticipants, giveExtraTime, deleteParticipantCard, setMatch, addProtest, giveTechnicalLose } = controlBoardSlice.actions;
