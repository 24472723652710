import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import PenaltyCardComponent from 'pages/standalone/components/PenaltyCardComponent';
const ParticipantScoreboardComponent = (props) => {
    const participant = useSelector((state) => state.controlBoardReducer.participants[props.participantNumber]);
    const colors = useSelector((state) => state.controlBoardReducer.colors);
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.scoreBoardContainer), { backgroundColor: colors[props.participantNumber] }) }, { children: [_jsx("div", { style: styles.separator }), _jsxs("div", Object.assign({ style: styles.participantMeetingInfo }, { children: [participant.isPriorityParticipant
                        ? _jsx("img", { src: '/participantCrown.svg', alt: 'participantCrown', style: { marginRight: '1rem' } })
                        : null, _jsx(Typography, Object.assign({ style: styles.participantName }, { children: participant.name })), participant.cards.map((card, cardIndex) => {
                        return _jsx(PenaltyCardComponent, { participantNumber: props.participantNumber, cardIndex: cardIndex, card: card, deletable: false });
                    })] })), _jsx(Typography, Object.assign({ style: styles.participantCount }, { children: participant.count })), _jsx("div", { style: styles.separator })] })));
};
const styles = {
    scoreBoardContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    participantName: {
        textAlign: 'center',
        fontSize: 52,
        fontWeight: '600',
    },
    participantCount: {
        textAlign: 'center',
        fontSize: 400,
        fontWeight: '400',
    },
    separator: {
        flex: 1
    },
    participantMeetingInfo: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
};
export default ParticipantScoreboardComponent;
