import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container, Typography } from '@mui/material';
const EmptyListComponent = (props) => {
    return (_jsxs(Container, Object.assign({ style: styles.container }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', color: 'rgba(0, 0, 0, 0.50)' }, { children: "\u0417\u0434\u0435\u0441\u044C \u043F\u043E\u043A\u0430 \u043F\u0443\u0441\u0442\u043E" })), _jsx(Button, Object.assign({ style: styles.createButtonContainer, href: props.navigate }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C" })) }))] })));
};
export default EmptyListComponent;
const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    createButtonContainer: {
        height: '2.5rem',
        width: '19.0625rem',
        marginTop: '1.5625rem',
    },
};
