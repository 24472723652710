import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
const PlayoffParticipantItemComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.participantGridItem }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: { marginRight: '1rem' } }, { children: props.participant.rating })), _jsx(Typography, Object.assign({ variant: 'h4' }, { children: props.participant.name })), _jsx("div", { style: { flex: 1 } })] })));
};
export default PlayoffParticipantItemComponent;
const styles = {
    participantGridItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '3.25rem',
        borderRadius: '0.625rem',
        alignItems: 'center',
        padding: '0 1rem',
        border: '0.0625rem solid #000',
    },
};
