import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { deleteParticipant, setPlayoffRounds } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const ParticipantsListComponent = () => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const stages = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages);
    const participants = stages.length > 0
        ? stages[stageAddress].participants
        : useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].participants);
    const dispatch = useDispatch();
    const deleteParticipantCallback = (participantId) => {
        dispatch(deleteParticipant({
            eventAddress,
            tournamentAddress,
            stageAddress,
            participantId
        }));
        dispatch(setPlayoffRounds({
            eventAddress,
            tournamentAddress,
            stageAddress
        }));
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ style: styles.participantsTableHeaderContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: styles.participantsTableHeaderText }, { children: "\u0418\u043C\u044F" })), _jsx("div", { style: { flex: 1 } }), _jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.participantsTableHeaderText), { width: '9rem' }) }, { children: "\u0422\u0440\u0435\u043D\u0435\u0440" })), _jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.participantsTableHeaderText), { width: '6.1875rem' }) }, { children: "\u0423\u0440\u043E\u0432\u0435\u043D\u044C" }))] })), _jsx("div", Object.assign({ style: { marginTop: '1.625rem' } }, { children: participants.map(participant => {
                    return _jsxs("div", Object.assign({ style: styles.participantsListItemContainer }, { children: [_jsxs("div", Object.assign({ style: styles.participantNameColumn }, { children: [_jsx(Typography, Object.assign({ variant: 'h4' }, { children: participant.name })), _jsx(Typography, Object.assign({ variant: 'h4', style: { color: 'rgba(0, 0, 0, 0.5)' } }, { children: participant.nameClub }))] })), _jsx("div", { style: { flex: 1 } }), _jsx(Typography, Object.assign({ variant: 'h4', style: { width: '9rem', } }, { children: participant.trainerName })), _jsx(Typography, Object.assign({ variant: 'h4', style: styles.participantRatingColumn }, { children: participant.rating })), _jsx(Button, Object.assign({ onClick: () => deleteParticipantCallback(participant.id), style: { position: 'absolute', right: '-6rem' } }, { children: _jsx(Typography, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }) }))] }), participant.id);
                }) }))] }));
};
export default ParticipantsListComponent;
const styles = {
    participantsTableHeaderContainer: {
        width: '60%',
        height: '1.5625rem',
        marginTop: '1.625rem',
        borderRadius: '0.625rem 0.625rem 0 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        backgroundColor: '#111',
    },
    participantsTableHeaderText: {
        color: '#D6FF35',
        textTransform: 'uppercase',
        fontWeight: '400',
    },
    participantsListItemContainer: {
        position: 'relative',
        width: '60%',
        height: '3.375rem',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.0625rem solid #6F6F6F',
        alignItems: 'center'
    },
    participantNameColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    participantRatingColumn: {
        width: '6.1875rem',
        textAlign: 'center'
    }
};
