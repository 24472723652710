import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const NonBell = () => {
    return (_jsx("div", Object.assign({ style: styles.svgContainer }, { children: _jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "37", height: "37", viewBox: "0 0 37 30", fill: "none" }, { children: [_jsx("g", Object.assign({ "clip-path": "url(#clip0_2153_2894)" }, { children: _jsx("path", { d: "M9.84852 25.0909H12.118C12.118 26.6006 13.3845 27.8182 14.9549 27.8182C16.5253 27.8182 17.7918 26.6006 17.7918 25.0909H20.0613C20.0613 27.7987 17.7715 30 14.9549 30C12.1383 30 9.84852 27.7987 9.84852 25.0909ZM29.7067 21.2727V21.8182C29.7067 23.026 28.6935 24 27.4372 24H2.47263C1.2163 24 0.203125 23.026 0.203125 21.8182V21.2727C0.203125 19.3539 1.68235 17.7565 3.60738 17.4935V10.9091C3.60738 4.87987 8.68337 0 14.9549 0C21.2264 0 26.3024 4.87987 26.3024 10.9091V17.4935C28.2274 17.7565 29.7067 19.3442 29.7067 21.2727ZM27.4372 21.2727C27.4372 20.3669 26.6773 19.6364 25.7249 19.6364C24.7827 19.6364 24.0329 18.8961 24.0329 18V10.9091C24.0329 6.08766 19.9701 2.18182 14.9549 2.18182C9.9397 2.18182 5.87688 6.08766 5.87688 10.9091V18C5.87688 18.8961 5.11701 19.6364 4.18489 19.6364C3.24264 19.6364 2.47263 20.3669 2.47263 21.2727V21.8182H27.4372V21.2727Z", fill: "#C5C5C5" }) })), _jsx("defs", { children: _jsx("clipPath", Object.assign({ id: "clip0_2153_2894" }, { children: _jsx("rect", { width: "37", height: "37", fill: "white" }) })) })] })) })));
};
export default NonBell;
const styles = {
    svgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
};
