const legendColors = [
    '#FF0000',
    '#0000FF',
    '#00FF00',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#FFFFFF'
];
export const participantsForLegendCounter = (tournamentParticipants) => {
    const temporaryParticipantsForLegend = {};
    const groups = [];
    tournamentParticipants.forEach((participant) => {
        if (`${participant.nameClub + ' - ' + participant.trainerName}` in temporaryParticipantsForLegend) {
            temporaryParticipantsForLegend[`${participant.nameClub + ' - ' + participant.trainerName}`].push(participant);
        }
        else {
            temporaryParticipantsForLegend[`${participant.nameClub + ' - ' + participant.trainerName}`] = [participant];
        }
    });
    for (let key in temporaryParticipantsForLegend) {
        groups.push([key, temporaryParticipantsForLegend[key]]);
    }
    groups.sort((a, b) => {
        return b[1].length - a[1].length;
    });
    return groups;
};
export const participantColor = (participantNameClub, participantTrainerName, groups) => {
    const index = groups.findIndex(element => element[0] == `${participantNameClub + ' - ' + participantTrainerName}`);
    return legendColors[index];
};
export const abbreviatedNameClub = (nameClub) => {
    const splitNameClub = nameClub.split(' ');
    if (splitNameClub.length > 1) {
        return splitNameClub.map((word) => word.slice(0, 1)).join('');
    }
    else {
        return splitNameClub[0].slice(0, 3);
    }
};
