import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { isPossibleMatch } from 'store/adminEventConfig/utils';
import { setPlayoffRounds, startPlayoff } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import PlayoffBracketComponent from 'components/PlayoffBracketComponents/PlayoffBracketComponent';
import ParticipantListItemComponent from './ParticipantListItemComponent';
const PlayoffStageBracket = (props) => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const [bracketSize, setBracketSize] = useState(0);
    const [isCorrectBracket, setIsCorrectBracket] = useState(true);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const isCorrectPlayoffStage = stage.type === 'playoff' ? stage.isCorrectPlayoff : true;
    const dispatch = useDispatch();
    const setPlayoffRoundsCallback = () => {
        dispatch(setPlayoffRounds({
            eventAddress,
            tournamentAddress,
            stageAddress
        }));
    };
    const checkIsCorrectPlayoff = () => {
        if (stage.type === 'playoff') {
            if (!isCorrectPlayoffStage) {
                const isCorrectPlayoff = isPossibleMatch(stage.playoffRounds, stage.playoffRounds.length - 1, 0);
                if (!isCorrectPlayoff) {
                    setIsCorrectBracket(false);
                    setTimeout(() => {
                        setIsCorrectBracket(true);
                    }, 3000);
                }
                else {
                    dispatch(startPlayoff({ eventAddress, tournamentAddress, stageAddress, isCorrectPlayoff }));
                }
            }
            else {
                dispatch(startPlayoff({ eventAddress, tournamentAddress, stageAddress, isCorrectPlayoff: false }));
            }
        }
    };
    return (props.stage
        ? _jsxs("div", Object.assign({ style: styles.mainContainer }, { children: [_jsx("div", Object.assign({ style: Object.assign(Object.assign({}, styles.container), { height: bracketSize }) }, { children: props.stage.playoffRounds && props.stage.playoffRounds[0]
                        ? _jsx(PlayoffBracketComponent, { playoffRounds: props.stage.playoffRounds, isSecretaryPage: false, setBracketSize: setBracketSize })
                        : _jsx(Typography, Object.assign({ variant: 'h2', style: { color: '#D6FF35' } }, { children: "\u0421\u0435\u0442\u043A\u0430 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442" })) })), _jsxs("div", Object.assign({ style: styles.buttonsContainer }, { children: [_jsx(Button, Object.assign({ style: styles.button, onClick: () => setPlayoffRoundsCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0421\u0433\u0435\u043D\u0435\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0441\u0435\u0442\u043A\u0443" })) })), _jsx(Button, Object.assign({ style: styles.button, onClick: () => checkIsCorrectPlayoff() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: !isCorrectPlayoffStage ? 'Начать плейофф' : 'Внести изменения' })) })), !isCorrectBracket
                            ? _jsx(Typography, Object.assign({ variant: 'h4', style: { color: 'red' } }, { children: "\u041D\u0435\u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u0430\u044F \u0441\u0435\u0442\u043A\u0430" }))
                            : null] })), !isCorrectPlayoffStage
                    ? _jsx(Grid, Object.assign({ container: true, spacing: 2, style: styles.gridContainer }, { children: props.stage.notDistributedParticipants.map((participant, participantIndex) => {
                            return props.stage
                                ? _jsx(ParticipantListItemComponent, { participant: participant, participantIndex: participantIndex, stage: props.stage }, participant.id)
                                : null;
                        }) }))
                    : null] }))
        : null);
};
export default PlayoffStageBracket;
const styles = {
    mainContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        overflowX: 'scroll',
    },
    container: {
        position: 'relative',
        display: 'flex',
        width: '100%'
    },
    gridContainer: {
        padding: 0,
        margin: '2rem 0'
    },
    button: {
        width: 'fit-content',
        marginRight: '2rem'
    },
    buttonsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        margin: '2rem 1rem',
    }
};
