import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { Button, Typography, Link } from '@mui/material';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const TournamentsMenuButtonsRow = (props) => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress } = useAdminPathParserHook();
    const isMutedButton = (buttonName) => {
        if (buttonName == 'description') {
            const gluedPathname = '/event/' + eventAddress + '/tournament/' + tournamentAddress;
            return pathname == gluedPathname;
        }
        else if (buttonName == 'stages') {
            return pathname.includes('stage');
        }
        else {
            return pathname.includes('participants');
        }
    };
    const paths = {
        'description': `/event/${eventAddress}/tournament/${props.tournament.tournamentAddress}`,
        'participants': `/event/${eventAddress}/tournament/${props.tournament.tournamentAddress}/participants`,
        'stages': `/event/${eventAddress}/tournament/${props.tournament.tournamentAddress}/stages`
    };
    return (_jsxs("div", Object.assign({ style: styles().buttonsContainer }, { children: [_jsx(Button, Object.assign({ component: Link, href: paths['description'], style: styles(isMutedButton('description')).buttons }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" })) })), _jsx(Button, Object.assign({ style: styles(isMutedButton('stages')).buttons, component: Link, href: paths['stages'] }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u042D\u0442\u0430\u043F\u044B" })) })), _jsx(Button, Object.assign({ style: styles(isMutedButton('participants')).buttons, href: paths['participants'], disabled: isMutedButton('participants') }, { children: _jsx(Typography, Object.assign({ variant: 'h4', style: styles().buttonText }, { children: "\u0423\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438" })) }))] })));
};
export default TournamentsMenuButtonsRow;
const styles = (isTransparentBackground) => ({
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '2.5rem',
        padding: 0,
    },
    buttons: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        border: '0.125rem solid #D6FF35',
        backgroundColor: isTransparentBackground ? 'transparent' : '#D6FF35',
    },
    buttonText: {
        lineHeight: 'normal',
        textTransform: 'uppercase'
    }
});
