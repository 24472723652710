import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
const ParticipantComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.participantContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h5', color: '#fff', style: styles.participantName }, { children: props.participant.name })), _jsx("div", Object.assign({ style: Object.assign(Object.assign({}, styles.participantCountContainer), { position: 'relative', borderRadius: props.isFirstParticipant ? '0 0.625rem 0 0' : '0 0 0.625rem 0', backgroundColor: (props.anotherParticipantCount || props.anotherParticipantCount === 0)
                        && props.participant.count > props.anotherParticipantCount
                        && props.isMatchCompleted
                        ? '#D6FF35'
                        : 'transparent' }) }, { children: _jsx(Typography, Object.assign({ variant: 'h5', color: (props.anotherParticipantCount || props.anotherParticipantCount === 0)
                        && props.participant.count > props.anotherParticipantCount
                        && props.isMatchCompleted
                        ? '#000'
                        : '#fff' }, { children: props.participant.count })) }))] })));
};
export default ParticipantComponent;
const styles = {
    participantContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '0.4375rem',
        height: '2.5rem'
    },
    participantCountContainer: {
        width: '2.5rem',
        height: '100%',
        borderLeft: '0.0625rem solid #fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    participantName: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        flexDirection: 'column',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    iconContainer: {
        position: 'absolute',
        bottom: '-0.95rem',
        zIndex: 2
    }
};
