import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import VerifiableTextField from 'pages/event/components/VerifiableTextField';
const SetupFieldComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.optionSettingContainer }, { children: [_jsx("div", { style: { flex: 1 } }), _jsx(Typography, Object.assign({ variant: 'h4', style: styles.title }, { children: props.title })), props.fieldType == 'select'
                ? _jsxs(Select, Object.assign({ id: props.fieldName, name: props.fieldName, disabled: !props.editable, value: props.formik.values[props.fieldName], onChange: props.formik.handleChange }, { children: [_jsx(MenuItem, Object.assign({ value: 'longSword' }, { children: "\u0414\u043B\u0438\u043D\u043D\u044B\u0439 \u041C\u0435\u0447" })), _jsx(MenuItem, Object.assign({ value: 'saber' }, { children: "\u0421\u0430\u0431\u043B\u044F" })), _jsx(MenuItem, Object.assign({ value: 'renaissanceRapier' }, { children: "\u0420\u0430\u043F\u0438\u0440\u0430 \u0420\u0435\u043D\u0435\u0441\u0441\u0430\u043D\u0441\u0430" })), _jsx(MenuItem, Object.assign({ value: 'rapierAndDaga' }, { children: "\u0420\u0430\u043F\u0438\u0440\u0430 \u0438 \u0414\u0430\u0433\u0430" })), _jsx(MenuItem, Object.assign({ value: 'swordAndBuckler' }, { children: "\u041C\u0435\u0447 \u0438 \u0411\u0430\u043A\u043B\u0435\u0440" })), _jsx(MenuItem, Object.assign({ value: 'sidesword' }, { children: "\u0421\u0430\u0439\u0434\u0441\u0432\u043E\u0440\u0434" }))] }))
                : _jsx(VerifiableTextField, { editable: props.editable, fieldName: props.fieldName, fieldType: props.fieldType, formik: props.formik, fromPage: props.fromPage })] })));
};
export default SetupFieldComponent;
const styles = {
    optionSettingContainer: {
        display: 'flex',
        height: '6rem',
        width: '12.3125rem',
        flexDirection: 'column',
        padding: 0,
    },
    title: {
        lineHeight: '1.5rem',
        marginBottom: '0.5rem',
        wordwrap: 'break-word',
        color: '#69696B'
    }
};
