import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import StagesMenuButtonsRow from 'pages/event/components/StagesMenuButtonsRow';
const stageTypeConverter = {
    'pools': 'Пулы',
    'playoff': 'Плей-офф',
    'swiss system': 'Швейцарская система'
};
const OneStageRowComponent = (props) => {
    return (_jsxs(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: [_jsxs("div", Object.assign({ style: styles.topRow }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', style: { lineHeight: '2.5rem' } }, { children: `Этап ${props.index + 1}. ${stageTypeConverter[props.stage.type]} ` })), _jsx("div", { style: { flex: 1 } }), _jsx(StagesMenuButtonsRow, { stageIndex: props.index })] })), _jsx("div", { children: _jsxs(Typography, Object.assign({ style: { whiteSpace: 'pre' } }, { children: [`Участники: ${props.participantLimit}`, " ", props.stage.isFinal == 'true'
                            ? '  Финальный'
                            : '  Выходят дальше: ' + props.stage.goToTheNext] })) })] })));
};
export default OneStageRowComponent;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
    topRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
};
