import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const ParticipantInMatchComponent = (props) => {
    return (_jsxs(Grid, Object.assign({ item: true, xs: 6, style: Object.assign(Object.assign({}, styles.participantContainer), { borderRight: props.participantIndex === 0
                ? '0.0625rem solid #C5C5C5'
                : 0 }) }, { children: [props.participantIndex === 0
                ? _jsx("div", Object.assign({ style: styles.participantsMatchSerialNumber }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: styles.yellowColorText }, { children: props.matchIndex + 1 })) }))
                : _jsx("div", Object.assign({ style: styles.participantsMatchSerialNumber }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: styles.whiteColorText }, { children: props.participant.count })) })), _jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.participantNameContainer), { backgroundColor: props.participantIndex === 0 ? '#E46E8B' : '#6E9EE4' }) }, { children: [_jsx(Typography, { children: props.participant.name }), _jsx(Typography, { children: props.participant.nameClub })] })), props.participantIndex % 2 == 0
                ? _jsx("div", Object.assign({ style: styles.participantsMatchSerialNumber }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: styles.whiteColorText }, { children: props.participant.count })) }))
                : null] })));
};
export default ParticipantInMatchComponent;
const styles = {
    participantContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '5rem',
        borderBottom: '0.0625rem solid #C5C5C5',
        padding: 0,
    },
    participantNameContainer: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '0.875rem',
    },
    participantsMatchSerialNumber: {
        width: '4.75rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    yellowColorText: {
        color: '#D6FF35'
    },
    whiteColorText: {
        color: '#D5DDDF'
    }
};
