import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container, Toolbar, Typography } from '@mui/material';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const EventNavigationBar = () => {
    const { pathname } = useLocation();
    const { eventAddress } = useAdminPathParserHook();
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const isMutedButton = (linkUrl) => {
        if (pathname.includes('tournament')) {
            return linkUrl.includes('tournaments');
        }
        else {
            if (linkUrl !== `/event/${eventAddress}`) {
                return pathname.includes(linkUrl);
            }
            else {
                return pathname.endsWith(eventAddress);
            }
        }
    };
    const menuItems = [
        { to: `/event/${eventAddress}`, title: 'Описание' },
        { to: `/event/${eventAddress}/tournaments`, title: 'Номинации' },
        { to: `/event/${eventAddress}/registration-settings`, title: 'Регистрация' },
        { to: `/event/${eventAddress}/applications`, title: 'Заявки' }
    ];
    return (_jsxs(Container, Object.assign({ sx: { bgcolor: 'secondary.main' }, style: styles().scoreBoardContainer, maxWidth: false }, { children: [_jsx(Typography, Object.assign({ variant: 'h1', style: styles().titleText }, { children: event.eventName })), _jsx(Toolbar, { children: menuItems.map((menuItem) => (_jsx(Button, Object.assign({ style: styles(isMutedButton(menuItem.to)).button, href: menuItem.to }, { children: _jsx(Typography, Object.assign({ variant: "h4", style: styles().buttonText }, { children: menuItem.title })) }), menuItem.title))) }), _jsx("div", Object.assign({ style: styles().layoutContainer }, { children: _jsx(Outlet, {}) }))] })));
};
export default EventNavigationBar;
const styles = (isTransparentBackground) => ({
    scoreBoardContainer: {
        width: '100vw',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        alignItems: 'center'
    },
    titleText: {
        marginTop: '1.625rem',
        marginBottom: '1.625rem',
        textAlign: 'center',
    },
    layoutContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
    },
    button: {
        border: '0.125rem solid #D6FF35',
        backgroundColor: isTransparentBackground ? 'transparent' : '#D6FF35',
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
    },
    buttonText: {
        lineHeight: 'normal',
        textTransform: 'uppercase'
    },
});
