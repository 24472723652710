import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import ParticipantInMatchComponent from './ParticipantInMatchComponent';
const PoolInfoComponent = () => {
    const secretaryCurrentPath = useSelector((state) => state.secretaryEventConfigReducer.secretaryCurrentPath);
    const { eventAddress, tournamentAddress, poolAddress } = useSecretaryPathParserHook(secretaryCurrentPath);
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const pool = stage.type === 'pools' ? stage.pools[poolAddress] : null;
    return (_jsxs(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: [_jsx(Typography, Object.assign({ variant: 'h1', color: 'text.primary', style: styles.titleText }, { children: `${'Пул ' + (poolAddress + 1)}` })), pool
                ? pool.matches.map((match, matchIndex) => {
                    return match.participants[0] && match.participants[1]
                        ? _jsxs(Grid, Object.assign({ container: true, spacing: 0, style: styles.participantsListContainer }, { children: [_jsx(ParticipantInMatchComponent, { participant: match.participants[0], participantIndex: 0, matchIndex: matchIndex }), _jsx(ParticipantInMatchComponent, { participant: match.participants[1], participantIndex: 1, matchIndex: matchIndex })] }))
                        : null;
                })
                : null] })));
};
export default PoolInfoComponent;
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        padding: '0 0 3rem 3.5rem',
    },
    titleText: {
        color: '#D6FF35',
        margin: '3.5rem 0'
    },
    participantsListContainer: {
        width: '69.5rem',
        borderTop: '0.0625rem solid #C5C5C5',
        padding: 0,
    },
};
