import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import WithTournamentsNavigationBar from 'pages/event/components/WithTournamentSubmenu';
import NewParticipantForm from './components/NewParticipantForm';
import CopyPastFieldComponent from './components/CopyPastFieldComponent';
import ParticipantsListComponent from './components/ParticipantsListComponent';
const TournamentParticipantsPage = () => (_jsxs(Container, Object.assign({ style: styles.mainContainer, maxWidth: false }, { children: [_jsx(NewParticipantForm, {}), _jsx(ParticipantsListComponent, {}), _jsx(CopyPastFieldComponent, {})] })));
export default WithTournamentsNavigationBar(TournamentParticipantsPage);
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingTop: '0.625rem',
        paddingBottom: '1.625rem',
    },
};
