import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { participantsForLegendCounter } from 'utils/clubLegendCounter';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import WithTournamentsNavigationBar from 'pages/event/components/WithTournamentSubmenu';
import WithStagesNavigationBar from 'pages/event/components/WithStagesNavigationBar';
import PoolsStageBracket from './components/PoolsStageBracket';
import PlayoffStageBracket from './components/PlayoffStageBracket';
const StageBracketPage = () => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const participants = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress].participants);
    const groups = participants && participants.length > 0 ? participantsForLegendCounter(participants) : [];
    const bracket = {
        'pools': _jsx(PoolsStageBracket, { poolStage: stage.type === 'pools' ? stage : null, groups: groups }),
        'playoff': _jsx(PlayoffStageBracket, { stage: stage && stage.type === 'playoff' ? stage : null }),
        'swiss system': _jsx("div", {})
    };
    return (_jsx(Container, Object.assign({ style: styles.mainContainer }, { children: bracket[stage.type] })));
};
export default WithTournamentsNavigationBar(WithStagesNavigationBar(StageBracketPage));
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingTop: '1.4375rem',
        paddingBottom: '4rem',
        alignItems: 'center',
    },
};
