export const seeding = (rounds) => {
    let players = [1, 2];
    const nextLayer = () => {
        const out = [];
        const length = players.length * 2 + 1;
        players.forEach((player) => {
            out.push(player);
            out.push(length - player);
        });
        return out;
    };
    for (let i = 0; i < rounds - 1; i++) {
        players = nextLayer();
    }
    return players;
};
