import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import TimerClockFace from 'components/TimerClockFace';
import ParticipantScoreboardComponent from './components/ParticipantScoreboardComponent';
const ScoreBoardPage = () => {
    const timerCounter = useSelector((state) => state.controlBoardReducer.timerCounter);
    const countdown = useSelector((state) => state.controlBoardReducer.countdown);
    const durationSeconds = useSelector((state) => state.controlBoardReducer.matchStage == 'mainStage'
        ? state.controlBoardReducer.mainStageDurationSeconds
        : state.controlBoardReducer.extraStageDurationSeconds);
    return (_jsxs(Container, Object.assign({ style: styles.scoreBoardContainer, maxWidth: false }, { children: [_jsx(ParticipantScoreboardComponent, { participantNumber: 0 }), _jsx("div", Object.assign({ style: styles.scoreTableContainer }, { children: _jsx(TimerClockFace, { timerCounter: timerCounter > 0 ? timerCounter : 0, numberColor: '#000', countdown: countdown, duration: durationSeconds }) })), _jsx(ParticipantScoreboardComponent, { participantNumber: 1 })] })));
};
export default ScoreBoardPage;
const styles = {
    scoreBoardContainer: {
        width: '100vw',
        display: 'flex',
        height: '100vh',
        flex: 1,
        flexDirection: 'row',
        padding: 0,
        overflow: 'hidden'
    },
    scoreTableContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 144,
        padding: '0 0.625rem',
        position: 'absolute',
        bottom: 185,
        left: '50%',
        msTransform: 'translate(-50%, 0)',
        transform: 'translate(-50%, 0)',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff'
    },
};
