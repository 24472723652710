import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { matchesGenerator } from 'utils/matchesGenerator';
const PoolsControlButtonsComponent = (props) => {
    const { eventAddress, tournamentAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const distributeRemainingCallback = () => {
        if (props.poolStage) {
            const pools = [];
            props.poolStage.pools.forEach(pool => {
                pools.push(Object.assign(Object.assign({}, pool), { participants: [...pool.participants] }));
            });
            const findSmallestPoolIndex = () => {
                let smallestPoolLength = props.poolStage.notDistributedParticipants.length;
                let smallestPoolIndex = 0;
                for (let j = 0; j < pools.length; j++) {
                    if (pools[j].participants.length < smallestPoolLength) {
                        smallestPoolLength = pools[j].participants.length;
                        smallestPoolIndex = j;
                    }
                }
                return smallestPoolIndex;
            };
            for (let i = 0; i < props.poolStage.notDistributedParticipants.length; i++) {
                pools[findSmallestPoolIndex()].participants.push(props.poolStage.notDistributedParticipants[i]);
            }
            for (let i = 0; i < pools.length; i++) {
                pools[i].matches = matchesGenerator(pools[i], i, tournament);
            }
            props.setPoolStage(Object.assign(Object.assign({}, props.poolStage), { pools: pools, notDistributedParticipants: [] }));
        }
    };
    const fillPoolsCallback = () => {
        if (props.poolStage) {
            const numberOfPools = props.poolStage.pools.length;
            let temporaryParticipants = [...props.poolStage.notDistributedParticipants];
            props.poolStage.pools.forEach(pool => {
                if (pool.participants.length > 0) {
                    temporaryParticipants = [...temporaryParticipants, ...pool.participants];
                }
            });
            temporaryParticipants.sort((firstParticipant, secondParticipant) => {
                return Number(secondParticipant.rating) - Number(firstParticipant.rating);
            });
            const pools = [];
            for (let i = 0; i < numberOfPools; i++) {
                pools.push(Object.assign(Object.assign({}, pools[i]), { participants: [], matches: [] }));
            }
            let currentPool = 0;
            let moveDirection = 'right';
            for (let i = 0; i < temporaryParticipants.length; i++) {
                pools[currentPool].participants.push(temporaryParticipants[i]);
                if (moveDirection == 'right') {
                    if ((i + 1) % numberOfPools != 0) {
                        currentPool++;
                    }
                    else {
                        moveDirection = 'left';
                    }
                }
                else {
                    if ((i + 1) % numberOfPools != 0) {
                        currentPool--;
                    }
                    else {
                        moveDirection = 'right';
                    }
                }
            }
            for (let i = 0; i < pools.length; i++) {
                pools[i].matches = matchesGenerator(pools[i], i, tournament);
            }
            props.setPoolStage(Object.assign(Object.assign({}, props.poolStage), { pools: pools, notDistributedParticipants: [] }));
        }
    };
    return (_jsxs("div", Object.assign({ style: styles.bottomButtonsContainer }, { children: [_jsx(Button, Object.assign({ style: styles.distributeRemainingParticipants, onClick: () => distributeRemainingCallback(), disabled: !(props.poolStage.pools.length > 0) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: 'Распределить оставшихся участников' })) })), _jsx(Button, Object.assign({ style: styles.distributeRemainingParticipants, onClick: () => fillPoolsCallback(), disabled: !(props.poolStage.pools.length > 0) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: 'Перегенерировать пулы' })) }))] })));
};
export default PoolsControlButtonsComponent;
const styles = {
    bottomButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2rem 0 1.5rem 0'
    },
    distributeRemainingParticipants: {
        height: '2.5rem',
        margin: '0 1.625rem'
    },
};
