import { jsx as _jsx } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NonBell from 'components/NonBell';
import IconProfile from 'components/IconProfile';
import LogoElement from 'components/LogoElement';
const AdminNavigationBar = () => {
    const menuItems = [
        { to: '#', title: 'Бойцы' },
        { to: '#', title: 'Рейтинг' },
        { to: '#', title: 'Турниры' },
        { to: '#', title: 'Клубы' },
        { title: '' },
        { to: '#', title: _jsx(NonBell, {}) },
        { to: '#', title: _jsx(IconProfile, {}) },
        { to: '#', title: _jsx(LogoElement, {}) }
    ];
    return (_jsx(AppBar, Object.assign({ color: "primary", position: 'relative' }, { children: _jsx(Toolbar, Object.assign({ style: styles.toolbar }, { children: menuItems.map((menuItem, index) => (menuItem.to
                ? _jsx(Box, Object.assign({ sx: { padding: typeof (menuItem.title) === 'string' ? '1rem' : '0 1rem' } }, { children: _jsx(Link, Object.assign({ component: RouterLink, to: menuItem.to, color: "secondary", sx: [{ textDecoration: 'none' }] }, { children: _jsx(Typography, Object.assign({ variant: "h3", component: "span", sx: { flexGrow: 1 }, color: "secondary" }, { children: menuItem.title })) })) }), index)
                : _jsx("div", { style: { flex: 1 } }, index))) })) })));
};
export default AdminNavigationBar;
const styles = {
    toolbar: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
};
