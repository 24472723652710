import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { POOL_STAGE_DATA } from 'store/mockData';
import { changePhase } from 'store/secretaryEventConfig/secretaryEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import translationPageManager from 'utils/translationPageManager';
import PoolsStatisticComponent from 'components/PoolsStatisticComponent';
const SecretaryPoolStatisticPage = () => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook();
    const { checkTranslationPageAreExisting } = translationPageManager();
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const participants = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress].participants);
    const sortParticipants = participants && participants[0]
        ? [...participants].sort((firstParticipant, secondParticipant) => {
            return (secondParticipant.win + secondParticipant.lose + secondParticipant.draw)
                - (firstParticipant.win + firstParticipant.lose + firstParticipant.draw);
        })
        : [];
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const poolStage = stage.type == 'pools' ? stage : POOL_STAGE_DATA;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changePhase({
            phaseTranslation: 'statisticPhase',
            currentSecretaryPath: pathname
        }));
        checkTranslationPageAreExisting();
    }, []);
    return (_jsx(PoolsStatisticComponent, { pool: poolStage, participants: sortParticipants, stageAddress: stageAddress }));
};
export default SecretaryPoolStatisticPage;
