import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { abbreviatedNameClub, participantColor } from 'utils/clubLegendCounter';
import PoolListItemComponent from './PoolListItemComponent';
const LegendAndPoolsComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.mainContentContainer }, { children: [_jsxs("div", Object.assign({ style: styles.legendContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.legendText), { marginBottom: '1.375rem' }) }, { children: "\u041B\u0435\u0433\u0435\u043D\u0434\u0430" })), props.groups.map(clubTrainer => {
                        return _jsx("div", Object.assign({ style: styles.legendListItem }, { children: _jsxs(Typography, Object.assign({ variant: 'h4', style: styles.legendText }, { children: [_jsx("span", Object.assign({ style: styles.legendText }, { children: clubTrainer[0] + ': (' })), _jsx("span", Object.assign({ style: Object.assign(Object.assign({}, styles.legendText), { color: participantColor(clubTrainer[1][0].nameClub, clubTrainer[1][0].trainerName, props.groups) }) }, { children: abbreviatedNameClub(clubTrainer[1][0].nameClub) })), _jsx("span", { children: ')' })] })) }), clubTrainer[0]);
                    })] })), _jsx(Grid, Object.assign({ container: true, spacing: 2, style: styles.gridContainer }, { children: props.poolStage.pools.map((pool, poolIndex) => {
                    return _jsx(PoolListItemComponent, { pool: pool, poolIndex: poolIndex, stage: props.poolStage, groups: props.groups, setPoolStage: props.setPoolStage }, poolIndex);
                }) }))] })));
};
export default LegendAndPoolsComponent;
const styles = {
    mainContentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        marginBottom: '1.9375rem'
    },
    legendContainer: {
        width: '16.25rem',
        marginRight: '3.25rem',
        padding: 0
    },
    legendListItem: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0.5rem 0 0.5rem 0'
    },
    legendText: {
        whiteSpace: 'normal'
    },
    gridContainer: {
        padding: 0,
        marginBottom: '2rem'
    },
};
