import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button, Container, Typography } from '@mui/material';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import EmptyListComponent from 'pages/event/components/EmptyListComponent';
import TournamentWithControls from './components/TournamentWithControls';
const TournamentsPage = () => {
    const { eventAddress } = useAdminPathParserHook();
    const tournaments = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments);
    const navigationLink = `/event/${eventAddress}/tournament/add`;
    return (_jsx(Container, Object.assign({ sx: { bgcolor: 'secondary.main' }, style: styles.container, maxWidth: false }, { children: Object.keys(tournaments).length > 0
            ? _jsxs(Container, Object.assign({ style: styles.tournamentsContainer }, { children: [Object.values(tournaments).map((tournament) => {
                        return _jsx(TournamentWithControls, { tournament: tournament, eventAddress: eventAddress }, tournament.tournamentAddress);
                    }), _jsx(Button, Object.assign({ style: styles.tournamentCreatButton, href: navigationLink }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043D\u043E\u043C\u0438\u043D\u0430\u0446\u0438\u044E" })) }))] }))
            : _jsx(EmptyListComponent, { navigate: navigationLink }) })));
};
export default TournamentsPage;
const styles = {
    container: {
        width: '100vw',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingLeft: '11rem',
        paddingRight: '11rem',
        paddingTop: '2.8125rem',
        paddingBottom: '3.0625rem',
        alignItems: 'center'
    },
    tournamentsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        alignItems: 'center'
    },
    tournamentCreatButton: {
        height: '2.5rem',
        width: '13.75rem',
        marginTop: '2.875rem'
    }
};
