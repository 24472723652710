import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import { seeding } from 'utils/seeding';
import MeetingComponent from './MeetingComponent';
import MeetingForThirdPlace from './MeetingForThirdPlace';
const PlayoffBracketComponent = (props) => {
    const { eventAddress, tournamentAddress } = props.isSecretaryPage ? useSecretaryPathParserHook() : useAdminPathParserHook();
    const stageAddress = props.isSecretaryPage
        ? useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex)
        : useAdminPathParserHook().stageAddress;
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const [recommendedParticipantNumbers, setRecommendedParticipantNumbers] = useState([]);
    useEffect(() => {
        let numberOfRounds = Math.ceil(Math.log(stage.participants.length) / Math.log(2) - 1) + 1;
        setRecommendedParticipantNumbers(seeding(numberOfRounds));
    }, []);
    const sizeUnit = 1.5;
    useEffect(() => {
        if (props.setBracketSize) {
            props.setBracketSize(containerHeight * 16 >= 300 ? `${containerHeight}rem` : '18.75rem');
        }
        let numberOfRounds = Math.ceil(Math.log(stage.participants.length) / Math.log(2) - 1) + 1;
        setRecommendedParticipantNumbers(seeding(numberOfRounds));
    }, [props.playoffRounds]);
    const containerWidth = props.playoffRounds && props.playoffRounds[0]
        ? 10 * ((props.playoffRounds.length) * 2 - 1) + 7
        : 0;
    const containerHeight = props.playoffRounds && props.playoffRounds[0]
        ? 6.5 * (props.playoffRounds[0].length / 2)
        : 0;
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.breakContainer), { width: `${containerWidth}rem`, height: `${containerHeight}rem`, backgroundColor: props.isSecretaryPage ? '#000' : '#c5c5c5' }) }, { children: [props.playoffRounds[0].length > 1
                ? props.playoffRounds.map((round, roundIndex) => {
                    return (round.map((match, matchIndex) => {
                        return (roundIndex !== (props.playoffRounds.length - 1)) || matchIndex < 1
                            ? _jsx(MeetingComponent, { match: match, matchIndex: matchIndex, sizeUnit: sizeUnit, playoffRoundsLength: props.playoffRounds.length, roundIndex: roundIndex, roundLength: round.length, isSecretaryPage: props.isSecretaryPage, recommendedParticipantNumbers: [recommendedParticipantNumbers[2 * matchIndex], recommendedParticipantNumbers[2 * matchIndex + 1]] }, matchIndex)
                            : null;
                    }));
                })
                : null, props.playoffRounds[props.playoffRounds.length - 1] && props.playoffRounds[props.playoffRounds.length - 1].length > 1
                ? _jsx(MeetingForThirdPlace, { match: props.playoffRounds[props.playoffRounds.length - 1][1], playoffRoundsLength: props.playoffRounds.length, sizeUnit: sizeUnit, isSecretaryPage: props.isSecretaryPage })
                : null] })));
};
export default PlayoffBracketComponent;
const styles = {
    breakContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
    },
};
