import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { seeding } from 'utils/seeding';
import MeetingComponent from './MeetingComponent';
import MeetingForThirdPlace from './MeetingForThirdPlace';
const PlayoffBracket = (props) => {
    const { eventAddress, tournamentAddress } = Object.assign({}, props);
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const tournament = event ? event.tournaments[tournamentAddress] : null;
    const stageAddress = tournament ? event.tournaments[tournamentAddress].currentStageIndex : null;
    const stage = (stageAddress || stageAddress === 0) && tournament
        ? tournament.stages[stageAddress]
        : null;
    const [recommendedParticipantNumbers, setRecommendedParticipantNumbers] = useState([]);
    useEffect(() => {
        if (props.setBracketSize) {
            props.setBracketSize(containerHeight * 16 >= 300 ? `${containerHeight}rem` : '18.75rem');
        }
    }, [props.playoffRounds]);
    useEffect(() => {
        if (stage) {
            let numberOfRounds = Math.ceil(Math.log(stage.participants.length) / Math.log(2) - 1) + 1;
            setRecommendedParticipantNumbers(seeding(numberOfRounds));
        }
    }, []);
    const sizeUnit = 1.5;
    const containerWidth = props.playoffRounds && props.playoffRounds[0]
        ? 10 * ((props.playoffRounds.length ? props.playoffRounds.length : 0) * 2 - 1) + 7
        : 0;
    const containerHeight = props.playoffRounds && props.playoffRounds[0]
        ? 6.5 * ((props.playoffRounds[0].length ? props.playoffRounds[0].length : 0) / 2)
        : 0;
    return (_jsx("div", Object.assign({ style: Object.assign(Object.assign({}, styles.mainContainer), { height: `${containerHeight}rem` }) }, { children: _jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.breakContainer), { width: `${containerWidth}rem`, height: `${containerHeight}rem` }) }, { children: [props.playoffRounds && props.playoffRounds[0] && props.playoffRounds[0].length > 1
                    ? props.playoffRounds.map((round, roundIndex) => {
                        return (round.map((match, matchIndex) => {
                            return (roundIndex !== (props.playoffRounds.length - 1)) || matchIndex < 1
                                ? _jsx(MeetingComponent, { match: match, matchIndex: matchIndex, sizeUnit: sizeUnit, playoffRoundsLength: props.playoffRounds.length, roundIndex: roundIndex, roundLength: round.length, recommendedParticipantNumbers: [recommendedParticipantNumbers[2 * matchIndex], recommendedParticipantNumbers[2 * matchIndex + 1]] }, matchIndex)
                                : null;
                        }));
                    })
                    : null, props.playoffRounds && props.playoffRounds[0] && props.playoffRounds[props.playoffRounds.length - 1] && props.playoffRounds[props.playoffRounds.length - 1].length > 1
                    ? _jsx(MeetingForThirdPlace, { match: props.playoffRounds[props.playoffRounds.length - 1][1], playoffRoundsLength: props.playoffRounds.length, sizeUnit: sizeUnit })
                    : null] })) })));
};
export default PlayoffBracket;
const styles = {
    mainContainer: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        overflowX: 'scroll',
        flex: 1,
        padding: 0,
        backgroundColor: '#000'
    },
    breakContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        alignItems: 'center',
        padding: 0,
    },
};
