import { useLocation } from 'react-router-dom';
export const useAdminPathParserHook = () => {
    const { pathname } = useLocation();
    const cutPath = pathname.split('/');
    return {
        eventAddress: cutPath.length > 2 ? cutPath[2] : '',
        tournamentAddress: cutPath.length > 4 ? cutPath[4] : '',
        stageAddress: cutPath.length > 6 ? Number(cutPath[6]) : 0
    };
};
