import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { Button, Container, Typography } from '@mui/material';
import { deleteTournament } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const TournamentWithControls = (props) => {
    const { eventAddress } = useAdminPathParserHook();
    const dispatch = useDispatch();
    const deleteTournamentCallback = () => {
        dispatch(deleteTournament({
            eventAddress: props.eventAddress,
            tournamentAddress: props.tournament.tournamentAddress,
            tournament: props.tournament,
        }));
    };
    const paths = {
        'description': `/event/${props.eventAddress}/tournament/${props.tournament.tournamentAddress}`,
        'participants': `/event/${eventAddress}/tournament/${props.tournament.tournamentAddress}/participants`,
        'stages': `/event/${eventAddress}/tournament/${props.tournament.tournamentAddress}/stages`,
    };
    return (_jsxs(Container, Object.assign({ style: styles.tournamentContainer }, { children: [_jsxs("div", Object.assign({ style: styles.leftTournamentSide }, { children: [_jsx(Typography, Object.assign({ variant: 'h2' }, { children: props.tournament.tournamentName })), _jsx(Typography, Object.assign({ onClick: () => deleteTournamentCallback(), variant: 'h4', style: { cursor: 'default' } }, { children: 'Удалить номинацию' }))] })), _jsxs("div", Object.assign({ style: styles.rightTournamentSide }, { children: [_jsxs("div", Object.assign({ style: styles.buttonsContainer }, { children: [_jsx(Button, Object.assign({ style: styles.buttons, href: paths['description'] }, { children: _jsx(Typography, Object.assign({ variant: 'h3' }, { children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" })) })), _jsx(Button, Object.assign({ style: styles.buttons, href: paths['participants'] }, { children: _jsx(Typography, Object.assign({ variant: 'h3' }, { children: "\u0423\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438" })) })), _jsx(Button, Object.assign({ style: styles.buttons, href: paths['stages'] }, { children: _jsx(Typography, Object.assign({ variant: 'h3' }, { children: "\u042D\u0442\u0430\u043F\u044B" })) }))] })), _jsxs("div", Object.assign({ style: styles.tournamentInfo }, { children: [_jsx("div", { style: { flex: 1 } }), _jsx(Typography, Object.assign({ variant: 'h4' }, { children: `Этапов: ${Object.keys(props.tournament.stages).length}` })), _jsx("div", { style: { width: '1rem' } }), _jsx(Typography, Object.assign({ variant: 'h4' }, { children: `Участников: ${props.tournament.stages[0] ? props.tournament.stages[0].participants.length : 0}` }))] }))] }))] })));
};
export default TournamentWithControls;
const styles = {
    tournamentContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '4.5rem',
        width: '100%',
        padding: 0,
        marginTop: '0.1875rem',
        marginBottom: '0.1875rem',
        justifyContent: 'space-between'
    },
    leftTournamentSide: {
        display: 'flex',
        flexDirection: 'column',
        height: '4.5rem',
        padding: 0,
    },
    rightTournamentSide: {
        display: 'flex',
        flexDirection: 'column',
        height: '4.5rem',
        padding: 0,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '3.125rem',
        padding: 0,
    },
    tournamentInfo: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
    buttons: {
        height: '2.5rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
    }
};
