import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { completePool } from 'store/adminEventConfig/adminEventConfig';
import { changePhase } from 'store/secretaryEventConfig/secretaryEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import translationPageManager from 'utils/translationPageManager';
import ParticipantInMatchComponent from './components/ParticipantInMatchComponent';
const SecretaryPoolPage = () => {
    const { pathname } = useLocation();
    const { eventAddress, tournamentAddress, poolAddress } = useSecretaryPathParserHook();
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const pool = stage.type === 'pools' ? stage.pools[poolAddress] : null;
    const { checkTranslationPageAreExisting } = translationPageManager();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(changePhase({
            phaseTranslation: 'poolPhase',
            currentSecretaryPath: pathname
        }));
        checkTranslationPageAreExisting();
    }, []);
    const typeConverter = (type) => {
        const types = {
            'pools': 'Пул',
            'playoff': 'Плейофф',
            'swiss system': 'Швейцарка'
        };
        return types[type];
    };
    const completePoolCallback = () => {
        dispatch(completePool({
            eventAddress,
            tournamentAddress,
            stageAddress,
            poolAddress
        }));
        navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage'}`);
    };
    return (_jsxs("div", Object.assign({ style: styles.container }, { children: [_jsx(Typography, Object.assign({ variant: 'h1', style: styles.tournamentNameText }, { children: tournament.tournamentName })), _jsx("div", Object.assign({ style: styles.currentGroupTextContainer }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: styles.yellowColorForText }, { children: pool
                        ? `Текущая группа: ${typeConverter(tournament.stages[tournament.currentStageIndex].type) + ' ' + (poolAddress + 1)}`
                        : 'Пул отсутствует' })) })), pool
                ? pool.matches.map((match, matchIndex) => {
                    return match.participants[0] && match.participants[1]
                        ? _jsxs(Grid, Object.assign({ container: true, spacing: 0, style: styles.gridContainer }, { children: [_jsx(ParticipantInMatchComponent, { match: match, participantNumber: 0, matchIndex: matchIndex, nextParticipants: matchIndex + 1 < pool.matches.length ? pool.matches[matchIndex + 1].participants : null }), _jsx(ParticipantInMatchComponent, { match: match, participantNumber: 1, matchIndex: matchIndex, nextParticipants: matchIndex + 1 < pool.matches.length ? pool.matches[matchIndex + 1].participants : null })] }), match.matchId)
                        : null;
                })
                : null, pool
                ? _jsx(Button, Object.assign({ style: styles.completePoolButton, onClick: () => completePoolCallback() }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C \u043F\u0443\u043B" })) }))
                : null] })));
};
export default SecretaryPoolPage;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        height: '100%',
        padding: '3.5rem 0',
        flexDirection: 'column',
        backgroundColor: '#000'
    },
    tournamentNameText: {
        color: '#fff',
        textAlign: 'center'
    },
    currentGroupTextContainer: {
        paddingLeft: '9.3125rem'
    },
    yellowColorForText: {
        color: '#D6FF35'
    },
    gridContainer: {
        padding: '0 1.6875rem'
    },
    completePoolButton: {
        width: '13rem',
        marginTop: '2.625rem',
        alignSelf: 'center'
    }
};
