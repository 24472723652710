import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import StageBracketTopRowComponent from './StageBracketTopRowComponent';
import LegendAndPoolsComponent from './LegendAndPoolsComponent';
import PoolsControlButtonsComponent from './PoolsControlButtonsComponent';
import ParticipantsListComponent from './ParticipantsListComponent';
const PoolsStageBracket = (props) => {
    const [poolStage, setPoolStage] = useState(props.poolStage);
    useEffect(() => {
        setPoolStage(props.poolStage);
    }, [props.poolStage]);
    return (poolStage
        ? _jsxs("div", Object.assign({ style: styles.poolStageContainer }, { children: [_jsx(StageBracketTopRowComponent, { poolStage: poolStage, participants: poolStage.participants, setPoolStage: setPoolStage, savedStage: props.poolStage }), _jsx(LegendAndPoolsComponent, { groups: props.groups, poolStage: poolStage, setPoolStage: setPoolStage }), _jsx(PoolsControlButtonsComponent, { poolStage: poolStage, setPoolStage: setPoolStage }), _jsx(ParticipantsListComponent, { poolStage: poolStage, groups: props.groups })] }))
        : null);
};
export default PoolsStageBracket;
const styles = {
    poolStageContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        alignItems: 'center',
    },
};
