import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
const TournamentCoverPicker = (props) => {
    return (_jsxs("div", { children: [_jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u041E\u0431\u043B\u043E\u0436\u043A\u0430 \u0442\u0443\u0440\u043D\u0438\u0440\u0430" })), _jsx(Typography, Object.assign({ variant: 'h4', style: styles.imageInfoText }, { children: "16:9" })), _jsx(TextField, { style: { width: '10.5rem' }, id: props.fieldName, name: props.fieldName, disabled: !props.editable, autoComplete: 'off', InputProps: {
                    inputProps: { min: 0 }
                }, placeholder: 'URL обложки', type: props.fieldType, value: props.formik.values[props.fieldName], onChange: props.formik.handleChange, error: props.formik.touched[props.fieldName] && Boolean(props.formik.errors[props.fieldName]), helperText: props.formik.touched[props.fieldName] && props.formik.errors[props.fieldName] })] }));
};
export default TournamentCoverPicker;
const styles = {
    uploadButton: {
        height: '2.5rem',
        width: '10.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageContainer: {
        height: '9rem',
        width: '14.875rem',
        marginTop: '2.5rem',
        marginBottom: '2.06rem',
        lineHeight: '9rem',
    },
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    imageInfoText: {
        color: 'rgba(0, 0, 0, 0.50)',
        marginBottom: '1.56rem'
    },
    errorMessageColor: {
        color: '#F00'
    }
};
