import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
const BattlePreparationParticipantComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.container), { backgroundColor: props.color }) }, { children: [_jsx("div", { style: { flex: 42 } }), _jsx(Typography, Object.assign({ style: { fontSize: '3.25rem' } }, { children: props.participant.name })), _jsx(Typography, Object.assign({ style: { fontSize: '2.5rem' } }, { children: props.participant.nameClub })), _jsx("div", { style: { flex: 16 } })] })));
};
export default BattlePreparationParticipantComponent;
const styles = {
    container: {
        display: 'flex',
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'center',
    }
};
