import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TimerClockFace from 'components/TimerClockFace';
import ParticipantScoreboardComponent from './ParticipantScoreboardComponent';
const ScoreboardPhaseComponent = () => {
    const timerCounter = useSelector((state) => state.controlBoardReducer.timerCounter);
    const countdown = useSelector((state) => state.controlBoardReducer.countdown);
    const durationSeconds = useSelector((state) => state.controlBoardReducer.matchStage == 'mainStage'
        ? state.controlBoardReducer.mainStageDurationSeconds
        : state.controlBoardReducer.extraStageDurationSeconds);
    const nextParticipants = useSelector((state) => state.controlBoardReducer.nextParticipants
        ? state.controlBoardReducer.nextParticipants
        : null);
    return (_jsxs(Container, Object.assign({ style: styles.scoreBoardContainer, maxWidth: false }, { children: [_jsx(ParticipantScoreboardComponent, { participantNumber: 1 }), _jsx("div", Object.assign({ style: styles.scoreTableContainer }, { children: _jsx(TimerClockFace, { timerCounter: timerCounter, numberColor: '#000', countdown: countdown, duration: durationSeconds }) })), nextParticipants
                ? _jsx(Typography, Object.assign({ style: styles.gettingReadyText }, { children: "\u0413\u043E\u0442\u043E\u0432\u044F\u0442\u0441\u044F" }))
                : null, _jsx(ParticipantScoreboardComponent, { participantNumber: 0 })] })));
};
export default ScoreboardPhaseComponent;
const styles = {
    scoreBoardContainer: {
        width: '100vw',
        display: 'flex',
        height: '100vh',
        flex: 1,
        flexDirection: 'row',
        padding: 0,
        overflow: 'hidden'
    },
    scoreTableContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '9rem',
        width: '24.125rem',
        position: 'absolute',
        bottom: '11.5625rem',
        left: '50%',
        msTransform: 'translate(-50%, 0)',
        transform: 'translate(-50%, 0)',
        borderRadius: '0.625rem',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff'
    },
    gettingReadyText: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: '1.875rem',
        left: '50%',
        fontSize: '3.25rem',
        lineHeight: '3.25rem',
        msTransform: 'translate(-50%, 0)',
        transform: 'translate(-50%, 0)',
        borderRadius: '0.625rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.50)'
    },
};
