import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
const PlayoffSizeRadio = (props) => {
    return (_jsxs(FormControl, Object.assign({ style: styles.inputField }, { children: [_jsx(FormLabel, Object.assign({ id: 'playoffSize' }, { children: _jsx(Typography, Object.assign({ variant: 'h4', color: '#69696B' }, { children: 'Размер плейоффа (кол-во бойцов)' })) })), _jsxs(RadioGroup, Object.assign({ name: 'playoffSize', "aria-labelledby": 'playoffSize', onChange: props.formik.handleChange, defaultValue: props.playoffSize ? props.playoffSize : '4' }, { children: [_jsx(FormControlLabel, { value: '4', control: _jsx(Radio, {}), label: '4' }), _jsx(FormControlLabel, { value: '6', control: _jsx(Radio, {}), label: '6' }), _jsx(FormControlLabel, { value: '8', control: _jsx(Radio, {}), label: '8' }), _jsx(FormControlLabel, { value: '12', control: _jsx(Radio, {}), label: '12' }), _jsx(FormControlLabel, { value: '16', control: _jsx(Radio, {}), label: '16' }), _jsx(FormControlLabel, { value: '24', control: _jsx(Radio, {}), label: '24' }), _jsx(FormControlLabel, { value: '32', control: _jsx(Radio, {}), label: '32' }), _jsx(FormControlLabel, { value: '48', control: _jsx(Radio, {}), label: '48' }), _jsx(FormControlLabel, { value: '64', control: _jsx(Radio, {}), label: '64' }), _jsx(FormControlLabel, { value: '128', control: _jsx(Radio, {}), label: '128' })] }))] })));
};
export default PlayoffSizeRadio;
const styles = {
    inputField: {
        width: '12.3125rem'
    }
};
