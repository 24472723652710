import { jsx as _jsx } from "react/jsx-runtime";
import Grid from '@mui/material/Grid';
import ParticipantListItemComponent from './ParticipantListItemComponent';
const ParticipantsListComponent = (props) => {
    return (_jsx(Grid, Object.assign({ container: true, spacing: 2, style: styles.gridContainer }, { children: props.poolStage.notDistributedParticipants.map((participant, participantIndex) => {
            return _jsx(ParticipantListItemComponent, { participant: participant, participantIndex: participantIndex, stage: props.poolStage, groups: props.groups }, participant.id);
        }) })));
};
export default ParticipantsListComponent;
const styles = {
    gridContainer: {
        padding: 0,
        marginBottom: '2rem'
    }
};
