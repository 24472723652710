import * as Yup from 'yup';
const TOO_LONG = 'Слишком много символов!';
const REQUIRED = 'Необходимо заполнить';
export const tournamentValidationSchema = Yup.object({
    tournamentName: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    weapon: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    tournamentAddress: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    matchDuration: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    theEndIsNighAlert: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    participantLimit: Yup.number()
        .required(REQUIRED)
        .max(255, TOO_LONG),
});
