import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme({
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: '4rem',
            fontWeight: '400',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: '400',
        },
        h3: {
            fontSize: '1.375rem',
            fontWeight: '400',
            fontStyle: 'normal',
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: '500',
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: '400'
        },
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#c5c5c5',
        },
        background: {
            default: '#D6FF35',
        },
        text: {
            primary: '#D6FF35',
            secondary: 'rgba(17, 17, 17, 0.5)',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    background: '#D6FF35',
                    boxShadow: '0 .25rem .25rem 0 rgba(86, 104, 13, 1)',
                    '&.Mui-disabled': {
                        border: '.125rem solid #D6FF35',
                        borderRadius: 10,
                        background: 'transparent',
                        boxShadow: '0 .25rem .25rem 0 rgba(86, 104, 13, 0.2)',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(214, 255, 53, 0.5)',
                        boxShadow: 'none',
                    },
                },
                text: {
                    color: '#111111',
                    '&.Mui-disabled': {
                        color: '#111111'
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#111111',
                    '&.Mui-checked': {
                        color: '#D6FF35',
                        "&:after": {
                            background: 'transparent',
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#111111',
                    borderRadius: 2,
                    '&.Mui-checked': {
                        color: '#D6FF35',
                        position: 'relative',
                        '&:after': {
                            content: '""',
                            left: 13,
                            top: 13,
                            height: 15,
                            width: 15,
                            position: 'absolute',
                            background: '#111111',
                            zIndex: -1
                        }
                    }
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '2.5rem',
                    [`& fieldset`]: {
                        borderRadius: '0.625rem',
                        border: '2px solid #000',
                    },
                },
                input: {
                    color: '#111',
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 35,
                    height: 20,
                    padding: 0,
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 15,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(9px)',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 3,
                        color: '#111111',
                        '&.Mui-checked': {
                            transform: 'translateX(15px)',
                            '& + .MuiSwitch-track': {
                                border: 0,
                                opacity: 1,
                                backgroundColor: '#D6FF35',
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                        width: 14,
                        height: 14,
                        borderRadius: 7,
                    },
                    '& .MuiSwitch-track': {
                        border: '.125rem solid #111111',
                        borderRadius: 20 / 2,
                        opacity: 1,
                        backgroundColor: 'transparent',
                        boxSizing: 'border-box',
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: '#c5c5c5'
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#000'
                }
            }
        }
    }
});
export default defaultTheme;
