import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { topMarginCounter } from 'utils/playoffTopMarginCounter';
import ParticipantComponent from './ParticipantComponent';
import EmptyParticipant from './EmptyParticipant';
const MeetingForThirdPlace = (props) => {
    const [hover, setHover] = useState(false);
    return (_jsx("div", Object.assign({ style: Object.assign(Object.assign({}, styles.meetingsColumnContainer), { left: `${10 * (props.playoffRoundsLength - 1) + 3}rem`, top: `${topMarginCounter(props.playoffRoundsLength - 1, 0, props.sizeUnit, props.playoffRoundsLength) + 9}rem` }), onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) }, { children: _jsxs("div", Object.assign({ style: styles.meetingContainer }, { children: [props.match.participants[0]
                    ? _jsx(ParticipantComponent, { participant: props.match.participants[0], anotherParticipantCount: props.match.participants[1] ? props.match.participants[1].count : null, isFirstParticipant: true, isMatchCompleted: props.match.isMatchCompleted, match: props.match, hover: hover, isSecretaryPage: props.isSecretaryPage, roundIndex: (props.playoffRoundsLength - 1), matchIndex: 1, isMatchForThirdPlace: true })
                    : _jsx(EmptyParticipant, { roundIndex: props.playoffRoundsLength - 1, matchIndex: 1, isFirstParticipant: true, isSecretaryPage: props.isSecretaryPage, isMatchForThirdPlace: true }), _jsx("div", { style: styles.participantsSeparator }), props.match.participants[1]
                    ? _jsx(ParticipantComponent, { participant: props.match.participants[1], anotherParticipantCount: props.match.participants[0] ? props.match.participants[0].count : null, isFirstParticipant: false, isMatchCompleted: props.match.isMatchCompleted, match: props.match, hover: hover, isSecretaryPage: props.isSecretaryPage, roundIndex: (props.playoffRoundsLength - 1), matchIndex: 1, isMatchForThirdPlace: true })
                    : _jsx(EmptyParticipant, { roundIndex: props.playoffRoundsLength - 1, matchIndex: 1, isFirstParticipant: false, isSecretaryPage: props.isSecretaryPage, isMatchForThirdPlace: true })] })) })));
};
export default MeetingForThirdPlace;
const styles = {
    meetingsColumnContainer: {
        width: '10rem',
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 0,
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
    },
    meetingContainer: {
        height: '5rem',
        width: '9rem',
        border: '0.0625rem solid #fff',
        display: 'flex',
        margin: 0,
        flexDirection: 'column',
        borderRadius: '0.625rem',
    },
    participantsSeparator: {
        height: '0.0625rem',
        width: '100%',
        backgroundColor: '#fff'
    },
};
