import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
const AmountAdvancingRadio = (props) => {
    return (_jsxs(FormControl, Object.assign({ style: styles.beforeTheFinalFormControl, disabled: props.formik.values.isFinal == 'true' }, { children: [_jsx(FormLabel, Object.assign({ id: 'goToTheNext' }, { children: _jsx(Typography, Object.assign({ variant: 'h4', color: '#69696B' }, { children: 'Выходят дальше' })) })), _jsxs(RadioGroup, Object.assign({ name: 'goToTheNext', "aria-labelledby": 'goToTheNext', onChange: props.formik.handleChange, defaultValue: props.formik.values.goToTheNext }, { children: [_jsx(FormControlLabel, { value: '2', control: _jsx(Radio, {}), label: '2' }), _jsx(FormControlLabel, { value: '4', control: _jsx(Radio, {}), label: '4' }), props.formik.values.type === 'pools'
                        ? _jsx(FormControlLabel, { value: '6', control: _jsx(Radio, {}), label: '6' })
                        : null, _jsx(FormControlLabel, { value: '8', control: _jsx(Radio, {}), label: '8' }), props.formik.values.type === 'pools'
                        ? _jsx(FormControlLabel, { value: '12', control: _jsx(Radio, {}), label: '12' })
                        : null, _jsx(FormControlLabel, { value: '16', control: _jsx(Radio, {}), label: '16' }), props.formik.values.type === 'pools'
                        ? _jsx(FormControlLabel, { value: '24', control: _jsx(Radio, {}), label: '24' })
                        : null, _jsx(FormControlLabel, { value: '32', control: _jsx(Radio, {}), label: '32' }), props.formik.values.type === 'pools'
                        ? _jsx(FormControlLabel, { value: '48', control: _jsx(Radio, {}), label: '48' })
                        : null, _jsx(FormControlLabel, { value: '64', control: _jsx(Radio, {}), label: '64' })] }))] })));
};
export default AmountAdvancingRadio;
const styles = {
    beforeTheFinalFormControl: {
        width: '12.3125',
    },
};
