export const matchesGenerator = (pool, poolIndex, tournament) => {
    const temporaryTours = [];
    const participants = pool.participants.length % 2 === 0 ? pool.participants : [...pool.participants, null];
    const participantsNumber = participants.length;
    let round = 0;
    for (let i = 1; i < participantsNumber; i++) {
        for (let j = i + 1; j <= participantsNumber; j++) {
            if (j < participantsNumber) {
                if (i + j - 1 < participantsNumber) {
                    round = i + j - 1;
                }
                else {
                    round = i + j - participantsNumber;
                }
            }
            else {
                if (2 * i <= participantsNumber) {
                    round = 2 * i - 1;
                }
                else {
                    round = 2 * i - participantsNumber;
                }
            }
            if (participants[i - 1] && participants[j - 1]) {
                temporaryTours[round - 1]
                    ? temporaryTours[round - 1].matches.push({
                        firstParticipant: participants[i - 1],
                        secondParticipant: participants[j - 1]
                    })
                    : temporaryTours[round - 1] = {
                        matches: [{
                                firstParticipant: participants[i - 1],
                                secondParticipant: participants[j - 1]
                            }]
                    };
            }
        }
    }
    const matches = [];
    for (let i = 0; i < temporaryTours.length; i++) {
        for (let j = 0; j < temporaryTours[i].matches.length; j++) {
            matches.push({
                matchStage: 'mainStage',
                stageType: 'pools',
                matchId: `${poolIndex + '_' + temporaryTours[i].matches[j].firstParticipant.id + '_' + temporaryTours[i].matches[j].secondParticipant.id}`,
                timerCounter: tournament.timerSettings === 'how much left' ? Number(tournament.matchDuration) * 1000 : 0,
                intervalID: null,
                participants: [temporaryTours[i].matches[j].firstParticipant, temporaryTours[i].matches[j].secondParticipant],
                timerLogs: [],
                countdown: tournament.timerSettings === 'how much left',
                mainStageDurationSeconds: Number(tournament.matchDuration),
                extraStageDurationSeconds: 30,
                colors: ['#E46E8B', '#6E9EE4'],
                nextParticipants: null
            });
        }
    }
    return matches;
};
