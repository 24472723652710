import { useRef } from 'react';
const translationPageManager = () => {
    const newWindow = useRef();
    const getWindow = () => {
        let storage = window.localStorage.getItem('translationPageExist');
        return storage !== null && storage !== void 0 ? storage : null;
    };
    const setWindow = (data) => {
        window.localStorage.setItem('translationPageExist', data);
    };
    const deleteWindow = () => {
        window.localStorage.setItem('translationPageExist', '');
    };
    const checkTranslationPageAreExisting = () => {
        if (getWindow()) {
            return;
        }
        else {
            openTranslationPage();
        }
    };
    const openTranslationPage = () => {
        var _a;
        newWindow.current = window.open('/translation');
        (_a = newWindow.current) === null || _a === void 0 ? void 0 : _a.document.body.appendChild(document.createElement('div'));
        const curWindow = newWindow.current;
        setWindow('window is opened');
        return () => curWindow.close();
    };
    return {
        deleteWindow,
        checkTranslationPageAreExisting,
        openTranslationPage
    };
};
export default translationPageManager;
