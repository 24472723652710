import { createSlice } from '@reduxjs/toolkit';
const secretaryEventConfig = createSlice({
    name: 'secretaryEventConfig',
    initialState: {},
    reducers: {
        changePhase(state, action) {
            state.phaseTranslation = action.payload.phaseTranslation;
            state.secretaryCurrentPath = action.payload.currentSecretaryPath;
        },
    }
});
export default secretaryEventConfig.reducer;
export const { changePhase, } = secretaryEventConfig.actions;
