import * as Yup from 'yup';
import { date } from 'yup';
import { isDate, parse } from 'date-fns';
const TOO_LONG = 'Слишком много символов!';
const REQUIRED = 'Необходимо заполнить';
export const eventValidationSchema = Yup.object({
    eventName: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    startDate: date().transform(parseDateString).required(),
    endDate: date().transform(parseDateString).required(),
    city: Yup.string()
        .required(REQUIRED)
        .max(255, TOO_LONG),
    eventAddress: Yup.string()
        .max(255, TOO_LONG),
    streamUrl: Yup.string()
        .max(255, TOO_LONG),
    description: Yup.string()
        .required(REQUIRED)
        .max(1000, TOO_LONG),
});
function parseDateString(value, originalValue) {
    return isDate(originalValue)
        ? originalValue
        : parse(originalValue, 'yyyy-MM-dd', new Date());
}
