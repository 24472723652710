export const addParticipantToNotDistributedList = (notDistributedList, addingParticipant, stageNumber) => {
    const participantInList = notDistributedList.find(iterationParticipant => {
        return iterationParticipant.id === addingParticipant.id;
    });
    let newNotDistributedList = [...notDistributedList];
    if (!participantInList) {
        newNotDistributedList.push(addingParticipant);
        if (newNotDistributedList.length > 1) {
            newNotDistributedList = newNotDistributedList.sort((firstParticipant, secondParticipant) => {
                const firstParticipantGames = firstParticipant.win + firstParticipant.lose + firstParticipant.draw;
                const secondParticipantGames = secondParticipant.win + secondParticipant.lose + secondParticipant.draw;
                if (stageNumber) {
                    return secondParticipantGames - firstParticipantGames;
                }
                else {
                    return secondParticipant.count - firstParticipant.count;
                }
            });
        }
    }
    return newNotDistributedList.sort((firstParticipant, secondParticipant) => {
        return Number(firstParticipant.rating) - Number(secondParticipant.rating);
    });
};
export const isPossibleMatch = (playoffRounds, roundIndex, matchIndex) => {
    let firstParticipantIsPossible = false;
    let secondParticipantIsPossible = false;
    if (playoffRounds[roundIndex][matchIndex].participants[0] && playoffRounds[roundIndex][matchIndex].participants[1]) {
        return true;
    }
    if (!playoffRounds[roundIndex][matchIndex].participants[0]) {
        if (roundIndex === 0) {
            firstParticipantIsPossible = false;
        }
        else {
            firstParticipantIsPossible = isPossibleMatch(playoffRounds, roundIndex - 1, matchIndex * 2);
        }
    }
    else {
        firstParticipantIsPossible = true;
    }
    if (!playoffRounds[roundIndex][matchIndex].participants[1]) {
        if (roundIndex === 0) {
            secondParticipantIsPossible = false;
        }
        else {
            secondParticipantIsPossible = isPossibleMatch(playoffRounds, roundIndex - 1, matchIndex * 2 + 1);
        }
    }
    else {
        secondParticipantIsPossible = true;
    }
    return firstParticipantIsPossible && secondParticipantIsPossible;
};
