import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { Container, TextField } from '@mui/material';
const DescriptionFieldComponent = (props) => {
    return (_jsx(Container, Object.assign({ style: styles.descriptionContainer }, { children: _jsxs(Typography, Object.assign({ variant: 'h4', style: styles.descriptionTextField }, { children: ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435*", _jsx("div", { style: { height: '0.5rem' } }), _jsx(TextField, { fullWidth: true, id: 'description', name: 'description', type: 'text', disabled: !props.editable, value: props.formik.values.description, onChange: props.formik.handleChange, error: props.formik.touched.description && Boolean(props.formik.errors.description), helperText: props.formik.touched.description && props.formik.errors.description, multiline: true, InputProps: { style: styles.descriptionTextInput }, style: styles.descriptionTextField })] })) })));
};
export default DescriptionFieldComponent;
const styles = {
    descriptionContainer: {
        height: '10.375rem',
        width: '100%',
        padding: 0
    },
    descriptionTextField: {
        width: '100%',
        color: 'rgb(105, 105, 107)'
    },
    descriptionTextInput: {
        width: '100%',
        height: '8.375rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top'
    },
};
