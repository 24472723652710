import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const IconProfile = () => {
    return (_jsx("div", Object.assign({ style: styles.svgContainer }, { children: _jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "37", height: "37", viewBox: "0 0 37 37", fill: "none" }, { children: [_jsxs("g", Object.assign({ "clip-path": "url(#clip0_2153_2893)" }, { children: [_jsx("circle", { cx: "18.5", cy: "18.5", r: "17.5", stroke: "#C5C5C5", "stroke-width": "2", "stroke-linecap": "round" }), _jsx("path", { d: "M26.5938 28.3281V26.7681C26.5938 25.6486 26.1211 24.5749 25.2799 23.7833C24.4387 22.9916 23.2977 22.5469 22.108 22.5469H14.892C13.7023 22.5469 12.5613 22.9916 11.7201 23.7833C10.8789 24.5749 10.4063 25.6486 10.4062 26.7681V28.3281", stroke: "#C5C5C5", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }), _jsx("path", { d: "M18.5 17.9219C21.0543 17.9219 23.125 15.8512 23.125 13.2969C23.125 10.7426 21.0543 8.67188 18.5 8.67188C15.9457 8.67188 13.875 10.7426 13.875 13.2969C13.875 15.8512 15.9457 17.9219 18.5 17.9219Z", stroke: "#C5C5C5", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" })] })), _jsx("defs", { children: _jsx("clipPath", Object.assign({ id: "clip0_2153_2893" }, { children: _jsx("rect", { width: "37", height: "37", fill: "white" }) })) })] })) })));
};
export default IconProfile;
const styles = {
    svgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
};
