import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { POOL_STAGE_DATA } from 'store/mockData';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import PoolsStatisticComponent from 'components/PoolsStatisticComponent';
const StatisticPhaseComponent = () => {
    const secretaryCurrentPath = useSelector((state) => state.secretaryEventConfigReducer.secretaryCurrentPath);
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook(secretaryCurrentPath);
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const participants = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]
        .participants.sort((firstParticipant, secondParticipant) => {
        return (secondParticipant.win + secondParticipant.lose + secondParticipant.draw)
            - (firstParticipant.win + firstParticipant.lose + firstParticipant.draw);
    }));
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const pool = stage.type == 'pools' ? stage : POOL_STAGE_DATA;
    return (_jsx(PoolsStatisticComponent, { pool: pool, participants: participants, stageAddress: stageAddress }));
};
export default StatisticPhaseComponent;
