import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import PoolsListComponent from 'components/PoolsListComponent';
const SecretaryPoolsStageComponent = (props) => {
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook();
    const stageAddress = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const navigate = useNavigate();
    const ratingNavigation = () => {
        if (stage.type == 'pools') {
            navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage' + '/rating'}`);
        }
    };
    return (_jsxs(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: [_jsx(Typography, Object.assign({ variant: 'h1', color: '#fff' }, { children: props.tournament.tournamentName })), _jsxs("div", Object.assign({ style: styles.ratingButtonContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2', color: 'text.primary' }, { children: `Этап ${props.tournament.currentStageIndex + 1}` })), _jsx(Button, Object.assign({ onClick: () => ratingNavigation(), style: styles.ratingButton }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: 'Рейтинг' })) }))] })), _jsx(PoolsListComponent, { stage: props.stage, isSecretaryPage: true })] })));
};
export default SecretaryPoolsStageComponent;
const styles = {
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '6.5rem',
        paddingBottom: '6.5rem'
    },
    ratingButtonContainer: {
        alignSelf: 'flex-start',
        marginLeft: '11.625rem',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '1rem',
        marginTop: '0.5rem'
    },
    ratingButton: {
        marginLeft: '1rem'
    },
};
