import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { POOL_STAGE_DATA } from 'store/mockData';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import SwissSystemStageComponent from './SwissSystemStageComponent';
import TranslationPoolsStageComponent from './TranslationPoolsStageComponent';
import PlayoffBracket from './translation-playoff-bracket-components/PlayoffBracket';
const StagesPhaseComponent = () => {
    var _a;
    const secretaryCurrentPath = useSelector((state) => state.secretaryEventConfigReducer.secretaryCurrentPath);
    const { eventAddress, tournamentAddress } = useSecretaryPathParserHook(secretaryCurrentPath);
    const event = useSelector((state) => state.adminEventConfigReducer[eventAddress]);
    const tournament = event ? event.tournaments[tournamentAddress] : null;
    const stageAddress = tournament ? event.tournaments[tournamentAddress].currentStageIndex : null;
    const stage = (stageAddress || stageAddress === 0) && tournament
        ? tournament.stages[stageAddress]
        : null;
    const stageType = {
        'pools': _jsx(TranslationPoolsStageComponent, { stage: stage && stage.type == 'pools' ? stage : POOL_STAGE_DATA }),
        'playoff': stage && stage.type === 'playoff' && stage.isCorrectPlayoff
            ? _jsx(PlayoffBracket, { playoffRounds: stage && stage.type === 'playoff' ? stage.playoffRounds : null, eventAddress: eventAddress, tournamentAddress: tournamentAddress, stageAddress: (_a = Number(stageAddress)) !== null && _a !== void 0 ? _a : '' })
            : null,
        'swiss system': _jsx(SwissSystemStageComponent, {})
    };
    return (_jsx(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: stage
            ? stageType[stage.type]
            : null })));
};
export default StagesPhaseComponent;
const styles = {
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};
