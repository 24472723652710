import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
const StatisticTableTextComponent = (props) => {
    return (_jsx("div", Object.assign({ style: styles(props.width, '').container }, { children: _jsx(Typography, Object.assign({ variant: props.variant, style: styles('', props.color).text }, { children: props.text })) })));
};
export default StatisticTableTextComponent;
const styles = (width, textColor) => ({
    container: {
        width: width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 0.75rem',
    },
    text: {
        display: 'flex',
        textTransform: 'uppercase',
        fontWeight: '400',
        color: textColor
    },
});
