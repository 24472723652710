import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { matchesGenerator } from 'utils/matchesGenerator';
import ParticipantInPoolComponent from './ParticipantInPoolComponent';
const PoolListItemComponent = (props) => {
    const { eventAddress, tournamentAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const ref = useRef(null);
    const [{ isOverCurrent }, drop] = useDrop({
        accept: ['participant'],
        collect(monitor) {
            return {
                isOverCurrent: monitor.isOver({ shallow: true }),
            };
        },
        drop(item) {
            if (!ref.current) {
                return;
            }
            const hoverIndex = props.poolIndex;
            props.setPoolStage;
            if (!item.fromPool && item.fromPool !== 0) {
                const temporaryNotDistributedParticipants = [...props.stage.notDistributedParticipants];
                const movingParticipant = temporaryNotDistributedParticipants.splice(item.movingParticipantIndex, 1)[0];
                const temporaryParticipants = [...props.stage.pools[hoverIndex].participants];
                const updatedPools = props.stage.pools.map((pool, index) => {
                    if (index === hoverIndex) {
                        return Object.assign(Object.assign({}, pool), { participants: temporaryParticipants });
                    }
                    else {
                        return pool;
                    }
                });
                updatedPools[hoverIndex].participants.push(movingParticipant);
                updatedPools[hoverIndex].participants.sort((firstParticipants, secondParticipants) => {
                    return Number(secondParticipants.rating) - Number(firstParticipants.rating);
                });
                updatedPools[hoverIndex].matches = matchesGenerator(updatedPools[hoverIndex], hoverIndex, tournament);
                props.setPoolStage(Object.assign(Object.assign({}, props.stage), { pools: updatedPools, notDistributedParticipants: temporaryNotDistributedParticipants }));
            }
            else {
                if (item.fromPool === hoverIndex) {
                    return;
                }
                const exportedPoolParticipants = [...props.stage.pools[item.fromPool].participants];
                const exportedParticipant = exportedPoolParticipants.splice(item.movingParticipantIndex, 1)[0];
                const importedPoolParticipants = [...props.stage.pools[hoverIndex].participants];
                importedPoolParticipants.push(exportedParticipant);
                importedPoolParticipants.sort((firstParticipants, secondParticipants) => {
                    return Number(secondParticipants.rating) - Number(firstParticipants.rating);
                });
                const updatedPools = [...props.stage.pools];
                updatedPools[item.fromPool] = {
                    matches: matchesGenerator({
                        participants: exportedPoolParticipants,
                        matches: []
                    }, item.fromPool, tournament),
                    participants: exportedPoolParticipants,
                };
                updatedPools[hoverIndex] = {
                    matches: matchesGenerator({
                        participants: importedPoolParticipants,
                        matches: []
                    }, hoverIndex, tournament),
                    participants: importedPoolParticipants,
                };
                props.setPoolStage(Object.assign(Object.assign({}, props.stage), { pools: updatedPools }));
            }
            item.movingParticipantIndex = hoverIndex;
        }
    });
    drop(ref);
    const deletePoolCallback = (poolAddress) => {
        const filteredPools = props.stage.pools.filter((pool, poolIndex) => {
            return poolIndex !== poolAddress;
        });
        const updatedNotDistributingParticipants = [
            ...props.stage.notDistributedParticipants,
            ...props.stage.pools[poolAddress].participants
        ].sort((firstParticipant, secondParticipant) => {
            return Number(secondParticipant.rating) - Number(firstParticipant.rating);
        });
        props.setPoolStage(Object.assign(Object.assign({}, props.stage), { pools: filteredPools, notDistributedParticipants: updatedNotDistributingParticipants }));
    };
    return (_jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsxs("div", Object.assign({ ref: ref, style: Object.assign(Object.assign({}, styles.gridItemContainer), { border: isOverCurrent ? '0.125rem solid #D6FF35' : '0.0625rem solid #000', margin: !(isOverCurrent) ? '0.0625rem' : 0 }) }, { children: [_jsxs("div", Object.assign({ style: styles.poolTopBarContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2' }, { children: `Пул ${props.poolIndex + 1}` })), _jsx(Button, Object.assign({ style: styles.deletePullButton, onClick: () => deletePoolCallback(props.poolIndex) }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" })) }))] })), props.pool.participants.map((participant, participantIndex) => {
                    return _jsx(ParticipantInPoolComponent, { participant: participant, poolIndex: props.poolIndex, participantIndex: participantIndex, groups: props.groups, stage: props.stage, setPoolStage: props.setPoolStage }, participant.id);
                })] })) })));
};
export default PoolListItemComponent;
const styles = {
    gridItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '0.0625rem solid #000',
        borderRadius: '0.625rem',
        padding: '1.3125rem',
    },
    poolTopBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem'
    },
    deletePullButton: {
        width: '5.365rem',
        height: '1.875rem'
    },
};
