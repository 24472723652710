import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
const ResultsTableHeader = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.participantsTableHeaderContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: Object.assign(Object.assign({}, styles.nameTableHeaderField), { color: '#D6FF35' }) }, { children: "\u0418\u043C\u044F" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem'), onClick: () => props.setParticipants('byPlace') }, { children: "\u041C\u0435\u0441\u0442\u043E" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem'), onClick: () => props.setParticipants('byWR') }, { children: "W/R" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem', '1rem'), onClick: () => props.setParticipants('byWins') }, { children: "\u041F\u043E\u0431\u0435\u0434" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('7.375rem', '1rem'), onClick: () => props.setParticipants('byLoses') }, { children: "\u041F\u043E\u0440\u0430\u0436\u0435\u043D\u0438\u0439" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('3.8125rem', '1rem'), onClick: () => props.setParticipants('byDraws') }, { children: "\u041D\u0438\u0447\u044C\u0438" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('6.625rem', '1rem'), onClick: () => props.setParticipants('byDone') }, { children: "\u041D\u0430\u043D\u0435\u0441\u0435\u043D\u043E" })), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('7.375rem'), onClick: () => props.setParticipants('byConceded') }, { children: "\u041F\u0440\u043E\u043F\u0443\u0449\u0435\u043D\u043E" })), _jsx("div", { style: { flex: 6 } }), _jsx(Typography, Object.assign({ variant: 'h4', style: textStyle('6.5rem'), onClick: () => props.setParticipants('byGoesFurther') }, { children: "\u041F\u0440\u043E\u0445\u043E\u0434\u0438\u0442" }))] })));
};
export default ResultsTableHeader;
const styles = {
    participantsTableHeaderContainer: {
        height: '1.5625rem',
        marginTop: '1.625rem',
        borderRadius: '0.625rem 0.625rem 0 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        backgroundColor: '#111',
    },
    participantsRowContainer: {
        height: '3.3125rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '1.8125rem',
        paddingRight: '1.8125rem',
        borderBottom: '0.0625rem solid #6F6F6F',
    },
    nameTableHeaderField: {
        color: '#D6FF35',
        textTransform: 'uppercase',
        fontWeight: '400',
        flex: 15
    },
};
const textStyle = (width, marginRight) => ({
    color: '#D6FF35',
    textTransform: 'uppercase',
    fontWeight: '400',
    textAlign: 'center',
    width: width,
    marginRight: marginRight ? marginRight : 0
});
