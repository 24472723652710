import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import StageTypeRadio from 'pages/event/components/StageTypeRadio';
import IsFinalStageSwitch from 'pages/event/components/IsFinalStageSwitch';
import AmountAdvancingRadio from 'pages/event/components/AmountAdvancingRadio';
import PlayoffSizeRadio from 'pages/event/components/PlayoffSizeRadio';
import ResolveTiesSwitch from './ResolveTiesSwitch';
const StageSettingsComponent = (props) => {
    return (_jsxs("form", Object.assign({ onSubmit: props.formik.handleSubmit, style: styles.formContainer }, { children: [_jsxs(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: [_jsx(StageTypeRadio, { formik: props.formik, editable: true }), _jsx(IsFinalStageSwitch, { formik: props.formik, editable: true }), _jsx(AmountAdvancingRadio, { formik: props.formik, editable: true }), props.formik.values.type == 'playoff'
                        ? _jsx(PlayoffSizeRadio, { formik: props.formik, editable: true, playoffSize: props.playoffSize })
                        : props.formik.values.type == 'pools'
                            ? _jsx(ResolveTiesSwitch, { formik: props.formik, editable: true })
                            : _jsx("div", { style: styles.inputField })] })), _jsxs("div", Object.assign({ style: styles.cancelSaveButtonsContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h4', style: styles.cancelButton, onClick: () => history.back() }, { children: "\u041E\u0442\u043C\u0435\u043D\u0430" })), _jsx(Button, Object.assign({ style: styles.saveButton, type: 'submit' }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })) }))] }))] })));
};
export default StageSettingsComponent;
const styles = {
    formContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '4rem'
    },
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginTop: '4rem',
        marginBottom: '4rem',
        padding: 0,
        justifyContent: 'space-between',
    },
    saveButton: {
        height: '2.5rem',
        padding: '0.5rem 1rem',
    },
    cancelButton: {
        cursor: 'default',
        marginRight: '1.75rem',
    },
    cancelSaveButtonsContainer: {
        display: 'flex',
        height: '2.5rem',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputField: {
        width: '12.3125rem'
    }
};
