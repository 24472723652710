import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Typography } from '@mui/material';
import { addParticipantToTournament, setPlayoffRounds } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import InputNewParticipantInfoFieldContainer from './InputFieldContainer';
const NewParticipantForm = () => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const participantLimit = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].participantLimit);
    const stages = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages);
    const participants = stages.length > 0
        ? stages[stageAddress].participants
        : useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].participants);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            nameClub: '',
            win: 0,
            lose: 0,
            draw: 0,
            trainerName: '',
            protestCounter: 0,
            count: 0,
            conceded: 0,
            cards: [],
            rating: '',
            status: false,
            reachedStage: 0,
            isPriorityParticipant: false,
            goesFurther: false
        },
        onSubmit: values => {
            if (Number(participantLimit) > participants.length) {
                dispatch(addParticipantToTournament({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    participant: Object.assign(Object.assign({}, values), { id: participants.length > 0
                            ? (Number(participants[participants.length - 1].id) + 1).toString()
                            : '0' }),
                }));
                dispatch(setPlayoffRounds({
                    eventAddress,
                    tournamentAddress,
                    stageAddress
                }));
                if (stages[stageAddress] && stages[stageAddress].type === 'playoff') {
                    dispatch(setPlayoffRounds({
                        eventAddress,
                        tournamentAddress,
                        stageAddress
                    }));
                }
            }
        },
    });
    return (_jsxs("form", Object.assign({ onSubmit: formik.handleSubmit, style: styles.addNewParticipantRowContainer }, { children: [_jsx(InputNewParticipantInfoFieldContainer, { width: '16.5625rem', title: 'Имя', fieldName: 'name', formik: formik }), _jsx(InputNewParticipantInfoFieldContainer, { width: '16.5625rem', title: 'Клуб', fieldName: 'nameClub', formik: formik }), _jsx(InputNewParticipantInfoFieldContainer, { width: '16.5625rem', title: 'Тренер', fieldName: 'trainerName', formik: formik }), _jsx(InputNewParticipantInfoFieldContainer, { width: '4.6875rem', title: 'Уровень', fieldName: 'rating', formik: formik }), _jsx(Button, Object.assign({ style: styles.addParticipantButton, type: "submit" }, { children: _jsx(Typography, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }) })), _jsx("div", { style: { flex: 1 } })] })));
};
export default NewParticipantForm;
const styles = {
    addNewParticipantRowContainer: {
        width: '100%',
        height: '4.5rem',
        display: 'flex',
        flexDirection: 'row',
    },
    addParticipantButton: {
        marginLeft: '2rem',
        height: '2.625rem',
        alignSelf: 'self-end'
    },
};
