import { createSlice } from '@reduxjs/toolkit';
const navigationBarConfig = createSlice({
    name: 'navigationBarConfig',
    initialState: {
        navigationType: 'adminNavigation'
    },
    reducers: {
        setDemoVersion(state, action) {
            state.navigationType = action.payload;
        }
    }
});
export default navigationBarConfig.reducer;
export const { setDemoVersion } = navigationBarConfig.actions;
