import { jsx as _jsx } from "react/jsx-runtime";
const VerticalLineComponent = (props) => {
    const recursiveHeightCounterForLines = (roundIndex) => {
        if (roundIndex > 0) {
            return 2 * recursiveHeightCounterForLines(roundIndex - 1);
        }
        else {
            return props.sizeUnit * 2;
        }
    };
    return (_jsx("div", Object.assign({ style: styles.verticalLineContainer }, { children: props.matchIndex % 2 == 0
            ? _jsx("div", { style: Object.assign(Object.assign({}, styles.verticalLine), { height: `${recursiveHeightCounterForLines(props.roundIndex)}rem`, top: '2.6875rem' }) })
            : _jsx("div", { style: Object.assign(Object.assign({}, styles.verticalLine), { height: `${recursiveHeightCounterForLines(props.roundIndex)}rem`, bottom: '2.6875rem' }) }) })));
};
export default VerticalLineComponent;
const styles = {
    verticalLineContainer: {
        height: '5.375rem',
        width: '0.0625rem',
        position: 'relative',
    },
    verticalLine: {
        width: '0.0625rem',
        position: 'absolute',
        backgroundColor: '#fff',
    },
};
