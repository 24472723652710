import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Button, Container, Typography } from '@mui/material';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import WithTournamentSubmenu from 'pages/event/components/WithTournamentSubmenu';
import EmptyListComponent from 'pages/event/components/EmptyListComponent';
import OneStageRowComponent from './components/OneStageRowComponent';
const StagesPage = () => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const navigateLink = `/event/${eventAddress}/tournament/${tournamentAddress}/stage/add`;
    return (_jsx(Container, Object.assign({ style: styles.container, maxWidth: false }, { children: tournament.stages && tournament.stages[0]
            ? _jsxs("div", Object.assign({ style: styles.container }, { children: [tournament.stages.map((stage, index) => {
                        return (_jsx(OneStageRowComponent, { stage: stage, index: index, participantLimit: index == 0
                                ? tournament.stages[stageAddress].participants.length.toString()
                                : tournament.stages[index - 1].goToTheNext }, index));
                    }), !tournament.stages[tournament.stages.length - 1].isFinal
                        ? _jsx(Button, Object.assign({ style: styles.addStageButton, href: navigateLink }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u0430\u043F" })) }))
                        : null] }))
            : _jsx(EmptyListComponent, { navigate: navigateLink }) })));
};
export default WithTournamentSubmenu(StagesPage);
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        alignItems: 'center',
        paddingBottom: '4rem',
    },
    addStageButton: {
        width: '13.75rem',
        marginTop: '4rem'
    }
};
