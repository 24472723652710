import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container, List, ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { dateToTimeFormatter } from 'utils/dateToTimeFormatter';
const TimerLogsComponent = () => {
    const timerLogs = useSelector((state) => state.controlBoardReducer.timerLogs);
    return (_jsx(Container, Object.assign({ style: styles.timerLogsContainer, maxWidth: false }, { children: _jsx(List, Object.assign({ style: styles.list }, { children: timerLogs.map((log, index) => {
                return _jsx(ListItemButton, Object.assign({ autoFocus: index == timerLogs.length - 1 }, { children: _jsx(Typography, Object.assign({ style: styles.logText }, { children: dateToTimeFormatter(log.time) + ': ' + log.logName })) }), index);
            }) })) })));
};
export default TimerLogsComponent;
const styles = {
    timerLogsContainer: {
        width: '17.625rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '3.25rem',
        paddingBottom: '3.25rem',
        overflow: 'hidden',
        backgroundColor: '#000',
    },
    list: {
        maxHeight: 300,
        overflow: 'auto',
        backgroundColor: '#000',
    },
    logText: {
        fontSize: '1.125rem',
        fontWeight: '400',
        color: '#D5DDDF'
    },
};
