import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import StatisticTableTextComponent from 'components/StatisticTableTextComponent';
const PoolsStatisticComponent = (props) => {
    const participantIndexCounter = (win, lose, draw) => {
        const allMatches = win + lose + draw;
        const winPoints = win + (draw / 2);
        return Number((allMatches === 0 ? 0 : winPoints / allMatches).toFixed(2));
    };
    const [sortedParticipants] = useState([...props.participants].sort((firstParticipant, secondParticipant) => {
        return participantIndexCounter(secondParticipant.win, secondParticipant.lose, secondParticipant.draw) - participantIndexCounter(firstParticipant.win, firstParticipant.lose, firstParticipant.draw);
    }));
    const participantCoefficientCounter = (participantId) => {
        let participantHits = 0;
        let participantDmg = 0;
        props.pool.completedMatches.forEach((match) => {
            if (match.participants[0] && match.participants[1]) {
                if (match.participants[0].id === participantId || match.participants[1].id === participantId) {
                    if (match.participants[0].id === participantId) {
                        participantHits += match.participants[0].count;
                        participantDmg += match.participants[1].count;
                    }
                    else if (match.participants[1].id == participantId) {
                        participantHits += match.participants[1].count;
                        participantDmg += match.participants[0].count;
                    }
                }
            }
        });
        return Number((participantHits - participantDmg).toFixed(2));
    };
    return (_jsxs(Container, Object.assign({ sx: { bgcolor: 'primary.main' }, style: styles.container, maxWidth: false }, { children: [_jsx(Typography, Object.assign({ variant: 'h1', color: 'text.primary', style: { marginBottom: '3rem' } }, { children: 'Этап ' + (props.stageAddress + 1) + '. Пулы' })), _jsxs("div", Object.assign({ style: styles.participantsTableHeaderContainer }, { children: [_jsx("div", { style: { width: '7.1875rem' } }), _jsx(Typography, Object.assign({ variant: 'h4', style: styles.participantsTableHeaderText }, { children: "\u0418\u043C\u044F" })), _jsx(StatisticTableTextComponent, { text: 'Бои', width: '4rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Победы', width: '5.625rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Поражения', width: '8rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Ничьи', width: '4.5rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Индекс', width: '5.125rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Коэффициент', width: '9.3125rem', variant: 'h4', color: '#000' }), _jsx(StatisticTableTextComponent, { text: 'Очки', width: '4rem', variant: 'h4', color: '#000' }), _jsx("div", { style: { width: '6.625rem' } })] })), sortedParticipants[0]
                ? sortedParticipants.map((participant, participantIndex) => {
                    return _jsxs("div", Object.assign({ style: styles.participantContainer }, { children: [_jsx("div", Object.assign({ style: styles.participantSerialNumber }, { children: _jsx(Typography, Object.assign({ variant: 'h2', style: { color: '#D5DDDF' } }, { children: participantIndex + 1 })) })), _jsx(Typography, Object.assign({ variant: 'h2', style: styles.participantNameText }, { children: participant.name })), _jsx(StatisticTableTextComponent, { text: (participant.win + participant.lose + participant.draw).toString(), width: '4rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: participant.win.toString(), width: '5.625rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: participant.lose.toString(), width: '8rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: participant.draw.toString(), width: '4.5rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: participantIndexCounter(participant.win, participant.lose, participant.draw).toString(), width: '5.125rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: participantCoefficientCounter(participant.id).toString(), width: '9.3125rem', variant: 'h4', color: '#D5DDDF' }), _jsx(StatisticTableTextComponent, { text: 'Очки', width: '4rem', variant: 'h4', color: '#D5DDDF' }), _jsx("div", { style: { width: '6.625rem' } })] }), participant.id);
                })
                : null, _jsx("div", { style: { flex: 1 } })] })));
};
export default PoolsStatisticComponent;
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem 3rem 3rem 3rem',
    },
    participantsTableHeaderContainer: {
        width: '100%',
        height: '1.5625rem',
        marginTop: '1.625rem',
        borderRadius: '0.625rem 0.625rem 0 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#D6FF35',
    },
    participantsTableHeaderText: {
        display: 'flex',
        flex: 1,
        textTransform: 'uppercase',
        fontWeight: '400',
    },
    participantContainer: {
        height: '6rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '0.0625rem solid #6F6F6F',
    },
    participantSerialNumber: {
        width: '7.1875rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    participantNameText: {
        flex: 1, color: '#D5DDDF'
    },
};
