import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import StagesMenuButtonsRow from './StagesMenuButtonsRow';
const WithStagesNavigationBar = (WrappedComponent) => (props) => {
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const stageTypeConverter = {
        'pools': 'Пулы',
        'playoff': 'Плей-офф',
        'swiss system': 'Швейцарская система'
    };
    return (stage
        ? _jsxs(Container, Object.assign({ style: styles.mainContainer, maxWidth: false }, { children: [_jsxs(Container, Object.assign({ style: styles.tournamentMenuContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h2' }, { children: `Этап ${Number(stageAddress) + 1}. ${stageTypeConverter[stage.type]} ` })), _jsx("div", { style: { width: '1rem' } }), _jsx(StagesMenuButtonsRow, { stageIndex: stageAddress })] })), _jsx(WrappedComponent, Object.assign({}, props))] }))
        : null);
};
export default WithStagesNavigationBar;
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingTop: '1.4375rem',
        marginTop: '-1.625rem',
    },
    tournamentMenuContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '2.5rem',
        width: '100%',
        padding: 0,
    },
};
