var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function register(config) {
    return __awaiter(this, void 0, void 0, function* () {
        if ('serviceWorker' in navigator) {
            const publicUrl = new URL('https://hfdb20.com', window.location.href);
            if (publicUrl.origin !== window.location.origin) {
                return;
            }
            try {
                window.addEventListener('load', () => {
                    const swUrl = `/service-worker.js`;
                    registerValidSW(swUrl, config);
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    });
}
function registerValidSW(swUrl, config) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const registration = yield navigator.serviceWorker.register(swUrl);
            navigator.serviceWorker.ready.then(function (swRegistration) {
                return swRegistration.sync.register('post-data');
            });
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        }
                        else {
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        }
        catch (error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
export function unregister() {
    return __awaiter(this, void 0, void 0, function* () {
        if ('serviceWorker' in navigator) {
            try {
                const registration = yield navigator.serviceWorker.ready;
                yield registration.unregister();
            }
            catch (error) {
                console.error('unregister Error', error);
            }
        }
    });
}
