export const timerFormatter = (secondsCount, countdown, duration) => {
    const prepareForDecimals = Math.floor(Number(secondsCount) / 100);
    const decimals = prepareForDecimals % 10;
    const prepareForTimer = Math.floor(Number(secondsCount) / 1000);
    const secondsToNumber = Number(prepareForTimer);
    const seconds = secondsToNumber % 60;
    const minutes = Math.floor(secondsToNumber / 60) % 60;
    const secondsToString = (seconds).toString().length < 2 ? '0' + seconds : seconds.toString();
    const minutesToString = minutes.toString().length < 2 ? '0' + minutes : minutes.toString();
    return secondsCount == '0' ? '00:00' : countdown
        ? `${minutesToString + ':' + secondsToString + (prepareForTimer > 9 || secondsCount == '0' ? '' : ':' + decimals)}`
        : `${minutesToString + ':' + secondsToString + (duration && duration * 1000 - 10000 < Number(secondsCount) ? ':' + decimals : '')}`;
};
