import { useLocation } from 'react-router-dom';
export const useSecretaryPathParserHook = (path) => {
    const { pathname } = useLocation();
    const cutPath = path ? path.split('/') : pathname.split('/');
    return {
        eventAddress: cutPath.length > 3 ? cutPath[3] : '',
        tournamentAddress: cutPath.length > 4 ? cutPath[4] : '',
        poolAddress: cutPath.length > 6 ? Number(cutPath[6].split('-')[1]) - 1 : -2
    };
};
