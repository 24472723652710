import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
const ControlBoardButton = (props) => {
    return (_jsxs("div", Object.assign({ style: styles.container }, { children: [props.helperText
                ? _jsx(Typography, Object.assign({ noWrap: true, variant: 'h4', style: styles.helperText }, { children: props.helperText }))
                : null, _jsx(Button, Object.assign({ onClick: props.pressBtn, style: { margin: '0 0.125rem' }, disabled: props.disabled }, { children: _jsx(Typography, Object.assign({ variant: 'h4' }, { children: props.text })) }))] })));
};
export default ControlBoardButton;
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    helperText: {
        position: 'absolute',
        top: '-1.5rem',
        alignSelf: 'center',
        color: 'rgba(213, 221, 223, 0.50)'
    }
};
