import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
const ParticipantScoreboardComponent = (props) => {
    const participant = useSelector((state) => state.controlBoardReducer.participants[props.participantNumber]);
    const nextParticipant = useSelector((state) => state.controlBoardReducer.nextParticipants
        ? state.controlBoardReducer.nextParticipants[props.participantNumber]
        : null);
    const colors = useSelector((state) => state.controlBoardReducer.colors);
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.scoreBoardContainer), { backgroundColor: colors[props.participantNumber] }) }, { children: [_jsx(Typography, Object.assign({ style: styles.participantName }, { children: participant.name })), _jsx(Typography, Object.assign({ style: styles.participantClub }, { children: participant.nameClub })), participant.protestCounter
                ? _jsx(Typography, Object.assign({ style: styles.protestsCounter }, { children: 'Протестов: ' + participant.protestCounter }))
                : null, _jsx("div", { style: styles.separator }), _jsx(Typography, Object.assign({ style: Object.assign(Object.assign({}, styles.participantClub), { fontSize: '25rem', lineHeight: '4.5rem' }) }, { children: participant.count })), _jsx("div", { style: styles.separator }), nextParticipant
                ? _jsxs("div", { children: [_jsx(Typography, Object.assign({ style: Object.assign(Object.assign({}, styles.participantName), { color: 'rgba(0, 0, 0, 0.50)' }) }, { children: nextParticipant.name })), _jsx(Typography, Object.assign({ style: Object.assign(Object.assign({}, styles.participantClub), { marginBottom: '1.875rem', color: 'rgba(0, 0, 0, 0.50)' }) }, { children: nextParticipant.nameClub }))] })
                : null] })));
};
const styles = {
    scoreBoardContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    participantName: {
        textAlign: 'center',
        fontSize: '3.25rem',
        fontWeight: '600',
    },
    participantClub: {
        textAlign: 'center',
        fontSize: '2.5rem',
        fontWeight: '400',
    },
    separator: {
        flex: 1
    },
    protestsCounter: {
        alignSelf: 'center',
        marginTop: '1rem',
        fontSize: '1.875rem'
    }
};
export default ParticipantScoreboardComponent;
