import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { abbreviatedNameClub, participantColor } from 'utils/clubLegendCounter';
const ParticipantItemComponent = (props) => {
    const [hover, setHover] = useState(false);
    return (_jsxs("div", Object.assign({ style: styles(hover).participantGridItem, onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) }, { children: [_jsxs("div", Object.assign({ style: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(Typography, Object.assign({ variant: 'h4' }, { children: props.participant.name + ' (' })), _jsx(Typography, Object.assign({ variant: 'h4', style: { color: participantColor(props.participant.nameClub, props.participant.trainerName, props.groups) } }, { children: abbreviatedNameClub(props.participant.nameClub) })), _jsx(Typography, Object.assign({ variant: 'h4' }, { children: ')' }))] })), _jsx(Typography, Object.assign({ variant: 'h4' }, { children: props.participant.rating }))] })));
};
export default ParticipantItemComponent;
const styles = (hover) => ({
    participantGridItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '3.25rem',
        borderRadius: '0.625rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 1rem',
        border: hover ? '0.0625rem dashed' : '0.0625rem solid #000',
        borderColor: hover ? '#D6FF35' : '#000'
    },
});
