import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { timerFormatter } from 'utils/timerFormatter';
const TimerClockFace = (props) => {
    return (_jsx(Typography, Object.assign({ style: Object.assign(Object.assign({}, styles.timeTable), { color: props.numberColor }) }, { children: timerFormatter(props.timerCounter
            ? Math.floor(props.timerCounter).toString()
            : '0', props.countdown, props.duration) })));
};
export default TimerClockFace;
const styles = {
    timeTable: {
        display: 'flex',
        fontSize: '8rem',
        fontWeight: '400',
        lineHeight: '4.5rem'
    },
};
