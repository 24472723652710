import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { deleteParticipantCard } from 'store/controlBoardSlice/controlBoardSlice';
const PenaltyCardComponent = (props) => {
    const dispatch = useDispatch();
    const deleteParticipantCardCallback = () => {
        if (props.deletable) {
            dispatch(deleteParticipantCard({
                indexOfCardToDeleted: props.cardIndex,
                participantNumber: props.participantNumber
            }));
        }
    };
    return _jsx("div", { style: cardStyle(props.card), onClick: () => deleteParticipantCardCallback() });
};
export default PenaltyCardComponent;
const cardStyle = (card) => ({
    minWidth: '1.9375rem',
    maxWidth: '1.9375rem',
    height: '2.625rem',
    borderRadius: '0.25rem',
    margin: '0 0.8125rem',
    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.50)',
    backgroundColor: card
});
