import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import { topMarginCounter } from 'utils/playoffTopMarginCounter';
import VerticalLineComponent from 'components/PlayoffBracketComponents/VerticalLineComponent';
import ParticipantComponent from './ParticipantComponent';
const MeetingComponent = (props) => {
    return (_jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.meetingsColumnContainer), { width: props.roundIndex === 0 ? '13rem' : '10rem', left: props.matchIndex < (props.roundLength / 2)
                ? `${10 * props.roundIndex + (props.roundIndex === 0 ? 0 : 2.8125)}rem`
                : `${10 * (2 * (props.playoffRoundsLength - 1) - props.roundIndex)
                    + (props.roundIndex === 0 ? 2.5625 : 2.8125)}rem`, top: `${topMarginCounter(props.roundIndex, props.matchIndex < (props.roundLength / 2)
                ? props.matchIndex
                : props.matchIndex % Math.floor(props.roundLength / 2), props.sizeUnit, props.playoffRoundsLength)}rem` }) }, { children: [props.roundLength > 2
                && props.matchIndex < props.roundLength / 2
                && props.roundIndex === 0
                ? _jsxs("div", Object.assign({ style: styles.tipsContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h5', style: styles.leftTipsText }, { children: props.recommendedParticipantNumbers[0] })), _jsx(Typography, Object.assign({ variant: 'h5', style: styles.leftTipsText }, { children: props.recommendedParticipantNumbers[1] }))] }))
                : null, props.roundLength > 2
                && props.matchIndex >= props.roundLength / 2
                && props.roundIndex < props.playoffRoundsLength - 2
                ? _jsx(VerticalLineComponent, { roundIndex: props.roundIndex, matchIndex: props.matchIndex, sizeUnit: props.sizeUnit })
                : null, props.roundIndex == 0 && props.matchIndex < props.roundLength / 2
                ? null
                : _jsx("div", { style: styles.horizontalLine }), _jsxs("div", Object.assign({ style: styles.meetingContainer }, { children: [props.match.participants[0]
                        ? _jsx(ParticipantComponent, { participant: props.match.participants[0], anotherParticipantCount: props.match.participants[1] ? props.match.participants[1].count : null, isFirstParticipant: true, isMatchCompleted: props.match.isMatchCompleted, match: props.match, roundIndex: props.roundIndex, matchIndex: props.matchIndex, isMatchForThirdPlace: false })
                        : _jsx("div", { style: { flex: 1 } }), _jsx("div", { style: styles.participantsSeparator }), props.match.participants[1]
                        ? _jsx(ParticipantComponent, { participant: props.match.participants[1], anotherParticipantCount: props.match.participants[0] ? props.match.participants[0].count : null, isFirstParticipant: false, isMatchCompleted: props.match.isMatchCompleted, match: props.match, roundIndex: props.roundIndex, matchIndex: props.matchIndex, isMatchForThirdPlace: false })
                        : _jsx("div", { style: { flex: 1 } })] })), props.roundIndex == 0 && props.matchIndex >= props.roundLength / 2
                ? null
                : _jsx("div", { style: styles.horizontalLine }), props.roundLength > 2
                && props.matchIndex < props.roundLength / 2
                && props.roundIndex < props.playoffRoundsLength - 2
                ? _jsx(VerticalLineComponent, { roundIndex: props.roundIndex, matchIndex: props.matchIndex, sizeUnit: props.sizeUnit })
                : null, props.roundLength > 2
                && props.matchIndex >= props.roundLength / 2
                && props.roundIndex === 0
                ? _jsxs("div", Object.assign({ style: styles.tipsContainer }, { children: [_jsx(Typography, Object.assign({ variant: 'h5', style: styles.rightTipsText }, { children: props.recommendedParticipantNumbers[0] })), _jsx(Typography, Object.assign({ variant: 'h5', style: styles.rightTipsText }, { children: props.recommendedParticipantNumbers[1] }))] }))
                : null] })));
};
export default MeetingComponent;
const styles = {
    meetingsColumnContainer: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
    },
    horizontalLine: {
        width: '0.5rem',
        height: '0.0625rem',
        backgroundColor: '#fff'
    },
    meetingContainer: {
        height: '5rem',
        width: '9rem',
        border: '0.0625rem solid #fff',
        display: 'flex',
        padding: 0,
        margin: 0,
        flexDirection: 'column',
        borderRadius: '0.625rem',
    },
    participantsSeparator: {
        height: '0.0625rem',
        width: '100%',
        backgroundColor: '#fff'
    },
    tipsContainer: {
        height: '5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '3rem',
        padding: 0,
    },
    leftTipsText: {
        width: '2rem',
        color: '#fff',
        textAlign: 'end',
    },
    rightTipsText: {
        width: '2rem',
        paddingLeft: '1rem',
        color: '#fff'
    },
};
