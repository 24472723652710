import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import Grid from '@mui/material/Grid';
import ParticipantItemComponent from './ParticipantItemComponent';
import PlayoffParticipantItemComponent from './PlayoffParticipantItemComponent';
const ParticipantListItemComponent = (props) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'participant',
        item: () => {
            const participant = Object.assign({}, props.participant);
            const movingParticipantIndex = props.participantIndex;
            const fromPool = false;
            return { movingParticipantIndex, fromPool, participant };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return (_jsx(Grid, Object.assign({ item: true, xs: 4 }, { children: _jsx("div", Object.assign({ ref: ref, style: Object.assign(Object.assign({}, styles.participantGridItem), { opacity }) }, { children: props.stage.type === 'pools'
                ? _jsx(ParticipantItemComponent, { participant: props.participant, groups: props.groups })
                : _jsx(PlayoffParticipantItemComponent, { participant: props.participant, participantIndex: props.participantIndex }) })) })));
};
export default ParticipantListItemComponent;
const styles = {
    participantGridItem: {
        height: '3.25rem',
    },
};
