import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Container } from '@mui/material';
import { changeStage } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import WithStagesNavigationBar from 'pages/event/components/WithStagesNavigationBar';
import WithTournamentsNavigationBar from 'pages/event/components/WithTournamentSubmenu';
import StageSettingsComponent from 'pages/event/components/StageSettingsComponent';
const StageDescriptionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { eventAddress, tournamentAddress, stageAddress } = useAdminPathParserHook();
    const participants = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress].participants);
    const stage = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].stages[stageAddress]);
    const playoffSize = stage.type === 'playoff' ? stage.playoffSize : null;
    const formik = useFormik({
        initialValues: stage,
        onSubmit: values => {
            dispatch(changeStage({
                eventAddress,
                tournamentAddress,
                stageAddress,
                stage: values.type == 'pools' && stage.type === 'pools'
                    ? Object.assign(Object.assign({}, values), { 
                        // @ts-ignore
                        isFinal: values.isFinal === 'true', 
                        // @ts-ignore
                        resolveTies: values.resolveTies === 'true', pools: stage.pools, notDistributedParticipants: participants }) : values.type === 'playoff' && stage.type === 'playoff'
                    ? Object.assign(Object.assign({}, values), { 
                        // @ts-ignore
                        isFinal: values.isFinal === 'true', participants: [], passedParticipants: [], playoffRounds: [], notDistributedParticipants: participants }) : Object.assign({}, values)
            }));
            navigate('/event/' + eventAddress + '/tournament/' + tournamentAddress + '/stages');
        },
    });
    return (_jsx(Container, Object.assign({ style: styles.mainContainer, maxWidth: false }, { children: _jsx(StageSettingsComponent, { formik: formik, playoffSize: playoffSize }) })));
};
export default WithTournamentsNavigationBar(WithStagesNavigationBar(StageDescriptionPage));
const styles = {
    mainContainer: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        paddingLeft: '8.375rem',
        paddingRight: '8.375rem',
    },
};
