import { useEffect } from 'react';
export const useStretchingText = (textClassName, containerIsChanged, initialMinFontSize = 3) => {
    const stretchingText = () => {
        let fontSize, maxHeight, maxWidth, parentElement, maxCycles = 50;
        const textElements = document.querySelectorAll(`.${textClassName}`);
        if (textElements.length) {
            textElements.forEach((element) => {
                parentElement = element.parentElement;
                maxWidth = parentElement.clientWidth;
                maxHeight = parentElement.clientHeight;
                fontSize = maxHeight;
                let minFontSize = initialMinFontSize;
                let maxFontSize = fontSize;
                element['style'].fontSize = `${fontSize}px`;
                while (fontSize !== minFontSize) {
                    element['style'].fontSize = `${fontSize}px`;
                    if (element['offsetHeight'] <= maxHeight &&
                        element['offsetWidth'] <= maxWidth) {
                        minFontSize = fontSize;
                    }
                    else {
                        maxFontSize = fontSize;
                    }
                    fontSize = Math.floor((minFontSize + maxFontSize) / 2);
                    --maxCycles;
                    if (maxCycles <= 0) {
                        break;
                    }
                }
                element['style'].fontSize = `${minFontSize}px`;
            });
        }
    };
    useEffect(() => {
        window.addEventListener('resize', stretchingText);
        stretchingText();
        return () => {
            window.removeEventListener('resize', stretchingText);
        };
    }, [containerIsChanged]);
};
