import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { changeTournament } from 'store/adminEventConfig/adminEventConfig';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
import { tournamentValidationSchema } from 'utils/tournamentValidationSchema';
import WithTournamentSubmenu from 'pages/event/components/WithTournamentSubmenu';
import TournamentSettingComponent from 'pages/event/components/TournamentSettingComponent';
const TournamentDescriptionPage = () => {
    const [editable, setEditable] = useState(false);
    const { eventAddress, tournamentAddress } = useAdminPathParserHook();
    const dispatch = useDispatch();
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const formik = useFormik({
        initialValues: tournament,
        validationSchema: tournamentValidationSchema,
        onSubmit: values => {
            if (editable) {
                setEditable(false);
                dispatch(changeTournament({
                    eventAddress,
                    tournamentAddress,
                    tournament: values
                }));
            }
            else {
                setEditable(true);
            }
        },
    });
    return (_jsx(Container, Object.assign({ style: styles.container }, { children: tournament
            ? _jsx(TournamentSettingComponent, { formik: formik, editable: editable, fromPage: 'tournamentDescription' })
            : null })));
};
export default WithTournamentSubmenu(TournamentDescriptionPage);
const styles = {
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
        overflow: 'hidden',
        paddingBottom: '3.125rem'
    },
};
