import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import Typography from '@mui/material/Typography';
import { setMatch } from 'store/controlBoardSlice/controlBoardSlice';
import { dropFromBracketToBracket, dropFromListToBracket } from 'store/adminEventConfig/adminEventConfig';
import { useSecretaryPathParserHook } from 'hooks/useSecretaryPathParserHook';
import { useAdminPathParserHook } from 'hooks/useAdminPathParserHook';
const ParticipantComponent = (props) => {
    const dispatch = useDispatch();
    const { eventAddress, tournamentAddress } = props.isSecretaryPage ? useSecretaryPathParserHook() : useAdminPathParserHook();
    const stageAddress = props.isSecretaryPage
        ? useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress].currentStageIndex)
        : useAdminPathParserHook().stageAddress;
    const tournament = useSelector((state) => state.adminEventConfigReducer[eventAddress].tournaments[tournamentAddress]);
    const stage = tournament.stages[stageAddress];
    const [isDruggingComponent, setIsDruggingComponent] = useState(!props.isSecretaryPage && stage && stage.type === 'playoff' && !stage.isCorrectPlayoff && !props.isMatchForThirdPlace);
    const navigate = useNavigate();
    const ref = useRef(null);
    useEffect(() => {
        setIsDruggingComponent(!props.isSecretaryPage && stage && stage.type === 'playoff' && !stage.isCorrectPlayoff && !props.isMatchForThirdPlace);
    }, [props.isSecretaryPage, stage && stage.type === 'playoff' ? stage.isCorrectPlayoff : null, props.isMatchForThirdPlace]);
    const [{ isDragging }, drag] = useDrag({
        type: 'participant',
        item: () => {
            const fromPlace = props.isFirstParticipant ? 0 : 1;
            const fromRound = props.roundIndex;
            const fromMatch = props.matchIndex;
            return { fromRound, fromMatch, fromPlace };
        },
        collect: (monitor) => {
            return {
                isDragging: isDruggingComponent ? monitor.isDragging() : null
            };
        }
    });
    const [{ isOverCurrent }, drop] = useDrop({
        accept: ['participant'],
        collect(monitor) {
            return {
                isOverCurrent: monitor.isOver({ shallow: true }),
            };
        },
        drop(item) {
            if (!ref.current || props.isMatchForThirdPlace) {
                return;
            }
            if (item.fromRound === props.roundIndex && item.fromMatch === props.matchIndex && item.fromPlace === (props.isFirstParticipant ? 0 : 1)) {
                return;
            }
            if (item.participant) {
                dispatch(dropFromListToBracket({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    toRound: props.roundIndex,
                    toMatch: props.matchIndex,
                    toPlace: props.isFirstParticipant ? 0 : 1,
                    participant: item.participant
                }));
            }
            else {
                dispatch(dropFromBracketToBracket({
                    eventAddress,
                    tournamentAddress,
                    stageAddress,
                    fromRound: item.fromRound,
                    fromMatch: item.fromMatch,
                    fromPlace: item.fromPlace,
                    toRound: props.roundIndex,
                    toMatch: props.matchIndex,
                    toPlace: props.isFirstParticipant ? 0 : 1,
                }));
            }
        }
    });
    const opacity = isDragging ? 0 : 1;
    !props.isSecretaryPage ? drop(drag(ref)) : null;
    const startMatchCallback = () => {
        if (stage.type === 'playoff' && props.match.participants[0] && props.match.participants[1]) {
            dispatch(setMatch(Object.assign(Object.assign({}, props.match), { stageType: 'playoff', participants: [props.match.participants[0], props.match.participants[1]], nextParticipants: null })));
        }
        navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage/match/start-settings'}`);
    };
    const editMatchCallback = () => {
        if (stage.type === 'playoff' && props.match.participants[0] && props.match.participants[1]) {
            dispatch(setMatch(Object.assign(Object.assign({}, props.match), { stageType: 'playoff', participants: [props.match.participants[0], props.match.participants[1]], nextParticipants: null })));
        }
        navigate(`${'/secretary/event/' + eventAddress + '/' + tournamentAddress + '/current-stage/match/control-panel'}`);
    };
    return (_jsxs("div", Object.assign({ ref: isDruggingComponent ? ref : null, style: Object.assign(Object.assign({}, styles.participantContainer), { opacity }) }, { children: [_jsx(Typography, Object.assign({ variant: 'h5', color: '#fff', style: styles.participantName }, { children: props.participant.name })), _jsxs("div", Object.assign({ style: Object.assign(Object.assign({}, styles.participantCountContainer), { borderRadius: props.isFirstParticipant ? '0 0.625rem 0 0' : '0 0 0.625rem 0', backgroundColor: (props.anotherParticipantCount || props.anotherParticipantCount === 0)
                        && props.participant.count > props.anotherParticipantCount
                        && !(props.isMatchCompleted && props.hover)
                        ? '#D6FF35'
                        : 'transparent' }) }, { children: [props.isFirstParticipant && (props.anotherParticipantCount || props.anotherParticipantCount === 0) && props.isSecretaryPage
                        ? !props.isMatchCompleted
                            ? _jsx("div", Object.assign({ style: styles.iconContainer, onClick: () => startMatchCallback() }, { children: _jsx("svg", Object.assign({ height: "1.3rem", viewBox: "0 0 512 512" }, { children: _jsx("path", { fill: '#D6FF35', d: "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" }) })) }))
                            : props.hover
                                ? _jsx("div", Object.assign({ style: styles.iconContainer, onClick: () => editMatchCallback() }, { children: _jsx("svg", Object.assign({ height: "1.3rem", viewBox: "0 0 512 512" }, { children: _jsx("path", { fill: '#D6FF35', d: "M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" }) })) }))
                                : null
                        : null, _jsx(Typography, Object.assign({ variant: 'h5', color: (props.anotherParticipantCount || props.anotherParticipantCount === 0)
                            && props.participant.count > props.anotherParticipantCount
                            && !(props.isMatchCompleted && props.hover)
                            ? '#000'
                            : '#fff' }, { children: props.participant.count }))] }))] })));
};
export default ParticipantComponent;
const styles = {
    participantContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '0.4375rem',
        height: '2.5rem'
    },
    participantCountContainer: {
        width: '2.5rem',
        height: '100%',
        borderLeft: '0.0625rem solid #fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    participantName: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        flexDirection: 'column',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    iconContainer: {
        position: 'absolute',
        bottom: '-0.95rem',
        cursor: 'pointer',
        zIndex: 2
    }
};
